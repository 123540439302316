import { Component } from '@angular/core';
import * as LogRocket from 'logrocket';
import { environment } from '../environments/environment';

if (environment.production) {
    LogRocket.init('fdm4sx/user-admin');
}

@Component({
    selector: 'body',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent { }
