import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth/auth.service';
import { Router } from '@angular/router';
import { CustomerService } from 'app/service/user/customer.service';
import { Customer } from 'app/interfaces';

@Component({
  selector: 'app-dashboard',
  template: ``,
  styles: []
})
export class DashboardComponent implements OnInit {

  constructor(
    public auth: AuthService,
    private router: Router,
    private userService: CustomerService,
  ) { }

  ngOnInit() {

    // get customer and get permits and route to requests if none are active
    this.userService.fetchPermits()
      .subscribe((permits) => {
        permits = permits.filter(permit => permit.status === 'Active' || permit.status === 'Customer Action Required');

        // check if local storage exists and route to saved path
        const url: string = localStorage.getItem('redirectUrl') || '/';
        localStorage.removeItem('redirectUrl');

        if (this.auth.profile.app_metadata.authorization.roles.includes('commuter_admin')) {

          if (url !== '/') {
            return this.router.navigateByUrl(url);
          } else {

            if (permits.length) {
              return this.router.navigate(['commuter-permits'])
            } else {
              return this.router.navigate(['permit-requests'])
            }

          }
        } else {
          return this.router.navigate(['invoices']);
        }
      })

  }

}
