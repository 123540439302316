<header class="app-header navbar">
  <button class="navbar-toggler mobile-sidebar-toggler hidden-lg-up" type="button">&#9776;</button>
  <a class="navbar-brand" [routerLink]="['/']"></a>
  <ul class="nav navbar-nav hidden-md-down">
    <li class="nav-item">
      <a class="nav-link navbar-toggler sidebar-toggler" href="#">&#9776;</a>
    </li>
  </ul>
</header>

<div class="app-body">
  <div class="w-100" *ngIf="loading | async else spin">
    <div class="sidebar">
      <nav class="sidebar-nav">
        <div *ngIf="auth.can('commuter', 'admin')"> <!--*ngIf="this.auth.can('commuter')"-->
          <ul class="nav">
            <li class="divider"></li>
            
            <li class="nav-title">
              Parking
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/commuter-permits']"><i class="fa fa-sticky-note"></i>Your Permits</a>
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/permit-requests']"><i class="fa fa-edit"></i>Permit Requests</a>
            </li>
            
            <li class="divider"></li>
            <li class="nav-title">
              Account Settings
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/commuter-vehicle']">
                <i class="fa fa-car"></i> Vehicles
              </a>
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/payments-on-file']">
                <i class="fa fa-credit-card"></i> Payment Method</a>
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link mobile-sidebar-toggler" routerLinkActive="active" [routerLink]="['/customer-profile']">
                <i class="fa fa-user"></i> User Profile</a>
            </li>
          </ul>
        </div>
        <div *ngIf="auth.can('fleet', 'admin')"> <!--*ngIf="this.auth.can('fleet')"-->
          <ul class="nav">
            <li class="divider"></li>
            <li class="nav-title">
              Fleet Settings
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/fleet-vehicle']">
                <i class="fa fa-truck"></i> Fleet Vehicles
              </a>
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link" [routerLink]="['/invoices']"><i class="fa fa-university"></i> Invoices</a>
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link" [routerLink]="['/payments-on-file']"><i class="fa fa-credit-card"></i> Payments</a>
            </li>

            <li class="divider"></li>
            <li class="nav-title">
              Integrations
            </li>
            <li class="nav-item mb-1" routerLinkActive="open">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/fleet-integration']"><i class="fa fa-server"></i> Extensions</a>
            </li>

          </ul>
        </div>
        <button class="btn btn-primary" (click)="logout()">Logout</button>
      </nav>
    </div>

    <!-- Main content -->
    <main class="main">

      <!-- Breadcrumb -->
      <ol class="breadcrumb">
        <breadcrumbs></breadcrumbs>
      </ol>

      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>

    </main>
  </div>
</div>

<footer class="app-footer">
    <span class="float-right">
      &copy; {{year}} <a href="https://meterfeeder.com">Meter Feeder</a>
    </span>
</footer>

<ng-template #spin>
  <h1>Loading</h1>
</ng-template>
