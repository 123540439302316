import {Deserializable} from './deserializable';
import {ObjectId} from 'app/interfaces'
import {Vehicle} from './vehicle.model';

export class InvoiceItem implements Deserializable {
  _id: ObjectId;
  charge: number;
  client: {
    type: ObjectId,
    ref: string
  };
  zone: {
      type: ObjectId,
      ref: string
  };
  start: Date;
  end: Date;
  sessionLength: number;
  deviceProvider: string;
  vehicle: Vehicle;
  fleet: {
      type: ObjectId,
      ref: string
  }

  deserialize(input: any): any {
    const invoiceItem = new InvoiceItem();
    Object.assign(invoiceItem, input);
    return invoiceItem;
  }
}