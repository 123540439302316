import {NgModule, ModuleWithProviders} from '@angular/core';
import {HeartlandPaymentService, IHeartlandPaymentServiceConfig} from './hps.service';
import {environment} from '../../../environments/environment';
/**
 * Created by danl on 9/6/17.
 */

let hpsConfig: IHeartlandPaymentServiceConfig = {
  publicKey: '',
  url: ''
};

@NgModule({
  providers: [
    HeartlandPaymentService,
    { provide: 'HEARTLAND_SERVICE_CONFIG', useValue: hpsConfig }
  ]
})
export class HeartlandPaymentModule {
  static withConfig(userConfig: IHeartlandPaymentServiceConfig): void {
    hpsConfig = userConfig;
    if (!hpsConfig.url) {
      hpsConfig.url = 'https://api.heartlandportico.com/SecureSubmit.v1/token/2.1/securesubmit.js';
    }

    HeartlandPaymentService.loaded = new Promise((resolve, reject) => {
      const node = document.createElement('script');
      node.src = hpsConfig.url;
      node.type = 'text/javascript';
      node.charset = 'utf-8';
      node.onload = function () {
        resolve(true);
      };
      node.onerror = function (err) {
        reject(err);
      };
      document.getElementsByTagName('head')[0].appendChild(node);
    })
  }
}


HeartlandPaymentModule.withConfig(environment.hps);
