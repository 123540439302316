import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {ErrorService} from '../error/error.service';
import {HpsSource, Source} from '../../models/source.model';
import { environment } from 'environments/environment';

export interface SourceRequest {
  offset: Number;
  limit: Number;
  totalMatchingRecords: Number;
  results: HpsSource[];
}

export interface CreateSourceRequest {
  firstName: string;
  lastName: string;
  nameOnAccount: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvince: string;
  zipPostalCode: string;
  country: string;
  paymentToken: string;
  expMon: number;
  expYear: number;
  primaryEmail: string;
}

@Injectable()
export class SourceService {

  private sourceMessage = new BehaviorSubject(undefined);
  currentSource = this.sourceMessage.asObservable();

  constructor(
    private auth: AuthService,
    private error: ErrorService,
  ) {}

  changeSource(source: Source) {
    this.sourceMessage.next(source);
  }

  // TODO
  public createFiservProfile(request) {
    // return this.auth.put_v2(`${environment.fiservProfileEndpoint}`, request);
  }

  public getSources(): Observable<SourceRequest> {
    return this.auth.get_v2<SourceRequest>('/customer/source').pipe(
      catchError(this.error.handleError('Get Sources', [])),
      map((input: SourceRequest) => {
        const res = Object.assign({}, input);
        res.results = input.results.map((r) => new HpsSource(r));
        return res;
      })
    );
  }

  public getSource(id: String) {
    return this.auth.get_v2<{source: Source}>(`/customer/source/${id}`).pipe(
      catchError(this.error.handleError(`Get Source ${id}`)),
      map((res: {source: Source}) => {
        return new Source().deserialize(res.source);
      })
    );
  }

  public saveSource(request: CreateSourceRequest): Observable<HpsSource> {
    return this.auth.post_v2('/customer/source', request).pipe(
      catchError(this.error.handleError('Save Source')),
      map(r => new HpsSource(r))
    )
  }

  public deleteSource(source: HpsSource) {
    return this.auth.delete_v2(`/customer/source/${source.paymentMethodIdentifier}`).pipe(
      catchError(this.error.handleError(`Delete Source ${source.paymentMethod}`))
    );
  }

  public setPrimarySource(source: any, id: string) {
    return this.auth.put_v2(`/customer/source/${id}`, source).pipe(
      catchError(this.error.handleError(`Update Source Failed, ${source.brand}, ${source.last4}`))
    );
  }
}

