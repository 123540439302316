import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from 'app/interfaces';
import { Zone } from 'app/models/zone.model';
import { AuthService } from 'app/service/auth/auth.service';
import { LocationService } from 'app/service/location/location.service';
import { CustomerService } from 'app/service/user/customer.service';
import { Auth0UserProfile } from 'auth0-js';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/**
 @Input() plan: Plan;
  @Output() onMakePermitRequest = new EventEmitter<Event>();
 */

@Component({
  selector: 'app-product-page',
  template: `
  <div class="container" style="margin-top: 15px; margin-bottom: 15px;">
    <div class="animated fadeIn">
      <div class="card">
        <div class="card-body">
          <div class="row" *ngIf="vm$ | async as vm">
            <div class="col">
              <lib-product-page
                  [loggedIn]="vm.isAuthed"
                  [plan]="vm.formattedPlan"
                  (onMakePermitRequest)="handleMakePermitRequest($event)"
                  (onShowLogin)="showLoginPopup($event)"
              ></lib-product-page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class PermitProductPageComponent implements OnInit {

  public planId: string;
  public formattedPlan;
  public formattedSkus = [];
  public isAuthed = false;
  public vm$: Observable<{
    isAuthed: boolean,
    formattedPlan: any,
    client: Client,
    zone: Zone,
  }>;

  constructor(
    public locationService: LocationService,
    public customerService: CustomerService,
    private route: ActivatedRoute,
    public router: Router,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.planId = this.route.snapshot.paramMap.get('id');
    if (!this.planId) {
      // Route to the search page, cause we can't load.
      return
    }

    this.loadVm();
  }

  private loadVm() {
    this.vm$ = zip(
      of(this.auth.isAuthenticated()),
      this.locationService.getPlanById(this.planId)
    ).pipe(
      switchMap(([isAuthed, plan]) => {
        return zip(
          of(isAuthed),
          of(plan),
          this.customerService.fetchClient(plan.client),
          this.locationService.getZoneById(plan.zone)
        );
      }),
      map(([isAuthed, plan, client, zone]) => {
        return {
          isAuthed,
          formattedPlan: this.formatPlan(plan, client, zone),
          client,
          zone
        };
      })
    );
  }

  public showLoginPopup(evt: Event): void {
    console.log('time to show login');
    this.auth.loginPopup()
    .subscribe((profile) => {
      if (profile) {
        this.loadVm();
      }
    }, (error) => {
      console.log(error);
    });
  }

  public handleMakePermitRequest(evt: Event): void {
    if (evt) {
      this.router.navigateByUrl(`/location/${this.planId}/${evt}`);
    }
  }

  public formatPlan(plan, client, zone) {
    this.formattedSkus = [];
    plan.skus.forEach(sku => {
      this.formattedSkus.push({
        ...sku,
        timeSlot: sku.name,
        uniqueLabel: sku.name,
        rate: sku.rate ? sku.rate : {value: 0, currency: 'USD'}
      });
    })

    this.formattedPlan = {
      ...plan,
      amenities: plan.amenities ? plan.amenities : [],
      client: client.displayName ? client.displayName : client.label,
      geometry: {
        latitude: zone.center?.coordinates[1] || 40,
        longitude: zone.center?.coordinates[0] || -80
      },
      skus: this.formattedSkus,
      url: plan.url ? plan.url : '',
      aboutHtml: plan.aboutHtml ? plan.aboutHtml : `The location of the parking lot is defined in the map to the right. Parking facilities are confirmed to be easily accessible with an entrance and exit.`,
      instructionsHtml: `Once your request has been approved, you will receive an email confirmation from ${client.displayName ? client.displayName : client.label} with instructions on how to begin using your permit. If you have any questions regarding the permit request process, please reach out to ${client.displayName ? client.displayName : client.label} directly at ${client.phone}.`,
      images: plan.images ? plan.images : [],
      slug: plan.slug ? plan.slug : '',
    }
    return this.formattedPlan;
  }

}
