<div class="container" style="margin-top: 20px;">
    <div class="animated fadeIn">
        <div class="card" style="margin-bottom: 0px;">
            <div class="card-header">
                <span><i class="fa fa-search"></i> Browse Permits</span>
            </div>
            <div class="card-body">
                <p>Enter Zip Code: </p>
                <form class="form-inline">
                    <div class="form-group">
                        <input type="text" class="form-control" (keyup)="setZipCode($event)"
                            style="margin-right: 10px;" />
                    </div>
                    <button type="submit" class="btn btn-primary" (click)="getCoordinates(zipCode)">Search</button>
                </form>

                <p *ngIf="plans" class="text-muted" style="margin-top: 15px">Displaying {{plans.length}} results near
                    {{zipCode}}.</p>
                <agm-map *ngIf="lat && lon" [latitude]="lat" [longitude]="lon" [zoom]="11">
                    <agm-marker *ngFor="let plan of plans" [latitude]="plan.region?.center()[1]"
                        [longitude]="plan.region?.center()[0]"
                        [label]="{color: 'white', text: (plans.indexOf(plan) + 1).toString()}"></agm-marker>
                </agm-map>

                <div style="margin-top: 20px;">
                    <div class="card" *ngFor="let plan of plans"
                        style="border-top: none; border-right: none; border-left: none; margin-bottom: 5px; padding-bottom: 10px;">
                        <div class="card-body row">
                            <div class="col-1">
                                <h4><span class="badge badge-danger">{{plans.indexOf(plan) + 1}}</span></h4>
                            </div>
                            <div class="col-7">
                                <h6>{{plan.name}}</h6>
                                <!-- <p>${{plan.amount.value/100}} - {{plan.frequency}}</p> -->
                                <div style="font-size: 12px;">
                                    <table class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <td>Time Slots</td>
                                                <td>Rate/Month</td>
                                                <td>Available Spaces</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let sku of plan.skus">
                                                <td>{{sku.name}}</td>
                                                <td *ngIf="sku.rate">${{(sku.rate.value/100).toFixed(2)}}</td>
                                                <td *ngIf="!sku.rate">${{(plan.amount.value/100).toFixed(2)}}</td>
                                                <td *ngIf="sku.inventory">{{calculateAvailableSpaces(sku.inventory, sku.count)}}</td>
                                                <td *ngIf="!sku.inventory">{{calculateAvailableSpaces(plan.totalSpaces, sku.count)}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-4">
                                <!-- <p *ngIf="plan.totalSpaces - plan.permits.length">There are
                                    <span style="font-weight: bold;">{{plan.totalSpaces -plan.permits.length}}</span>
                                    available spaces.
                                </p>
                                <p *ngIf="!(plan.totalSpaces - plan.permits.length)">There are
                                    <span style="font-weight: bold;">0</span> available spaces.
                                </p> -->

                                <div>
                                    <button type="button" style="margin-top: 25px"
                                        class="btn btn-primary" (click)="requestPermit(plan._id)">Request
                                        Permit</button>
                                    <!-- <button type="button" *ngIf="!(plan.totalSpaces - plan.permits.length)"
                                        class="btn btn-primary" (click)="requestPermit(plan._id)">Join
                                        Waitlist</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>