import { Component, OnInit } from '@angular/core';
import { LocationService } from '../../service/location/location.service';
import { Router } from '@angular/router';
import { PermitsService } from 'app/service/permit/permits.service';
import { Plan } from 'app/models/plan.model';
import { Region } from 'app/models/Region.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public zipCode: string;
  public plans: [];
  public lat: string;
  public lon: string;
  public billingInfo: boolean = true;
  public zoom: number;

  constructor(
    private router: Router,
    private locationService: LocationService,
  ) { }

  ngOnInit() {
    // this.getLocation();
  }

  public setZipCode(event) {
    this.zipCode = event.target.value;
  }

  public getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (pos) {
        this.lat = pos.coords.latitude;
        this.lon = pos.coords.longitude;
        this.getPlans();
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }

  public getCoordinates(zipCode) {
    this.locationService.getCoordinates(zipCode)
      .subscribe((res: any) => {
        this.lat = res.results[0].geometry.location.lat;
        this.lon = res.results[0].geometry.location.lng;
        this.getPlans();
      })
  }

  public getPlans() {
    this.locationService.getPlans(this.lon, this.lat)
      .subscribe((plans: any) => {
        this.plans = plans.map(plan => {
          return new Plan().deserialize(plan);
        });
        let origin = [parseFloat(this.lon), parseFloat(this.lat)];
        let region = new Region({ geometry: { coordinates: [origin] } });
        this.plans.forEach((plan: Plan) => {
          let center = plan.region && plan.region.center();
          if (center) {
            region.geometry.coordinates[0].push(center);
          }
          // this.locationService.getPermitsbyPlanId(plan._id)
          //   .subscribe((permits: any) => {
          //   })
        })
        this.zoom = region.zoom(300, 300);
      })
  }

  public requestPermit(id) {
    this.router.navigate(['location/' + id]);
  }

  public calculateAvailableSpaces(spaces, permits) {
    let result = spaces - permits;
    if (result >= 0) {
      return result;
    } else {
      return 0;
    }
  }

}
