import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {ObjectId} from 'app/interfaces';
import {catchError, map, retryWhen, switchMap} from 'rxjs/operators';
import {ErrorService} from '../error/error.service';
import {Permit} from '../../models/permit.model';
import {Schedule} from '../../models/schedule.model';
import {forkJoin, Observable,  of, throwError} from 'rxjs';
import {Region} from '../../models/Region.model';
import {PermitRequest} from '../../models/permit-request.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import {PermitTransaction} from '../../models/permit-transaction.model';

export enum Country {
  Usa = 'USA',
  Canada = 'CAD'
}

interface GetRegionResults {
  region: Region[];
}

export interface PermitPaymentSourceUpdate {
  nameOnAccount: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  zipPostalCode: string;
  stateProvince: string;
  country: Country;
  alternateIdentity: {
    token: string;
    expMon: number;
    expYear: number;
  }
}

export interface PermitCustomerUpdate {
  name: {
    first: string;
    last: string;
  };
  email: string;
  company?: string;
  phone?: string;
  address: Address;
  vehicle: any;
}

export interface Address {
  line1: string;
  line2: string;
  line3: string;
  city: string;
  state: string;
  zip: string;
  country?: Country.Usa,
}

@Injectable()
export class PermitsService {

  constructor(
    // private http: HttpClient,
    private auth: AuthService,
    private errors: ErrorService,
    private http: HttpClient,
  ) { }

  save(id: string, params: any, message?: string) {
    if (message) {
      params.type = message;
    }
    return this.auth.post_v1(`/permits/${id}`, params);
  }

  public createPermitRequest(permitRequest: PermitRequest): Observable<unknown> {
    return this.auth.post_v2('/permit-request', permitRequest)
      .pipe(
        catchError(this.errors.handleError('Error Saving Permit Request'))
      );
  }

  public activateRequest(requestId, body: {vehicle: any, firstName: string, lastName: string}): Observable<boolean> {
    return this.auth.patch_v2<boolean, any>(`/permit/${requestId}/activate`, body)
      .pipe(
        // catchError(this.errors.handleError<boolean>('Activate Permit', false, 'There was an error activating your permit')),
        map(() => true)
      )
  }

  public claimRequest(requestId, body: {paymentSource: any, autorenew: boolean}): Observable<boolean> {
    return this.auth.patch_v2<boolean, any>(`/permit-request/${requestId}/client-request-add-payment`, body)
      .pipe(
        map(() => true)
      )
  }

  public sendPermitInvitation(requestId: string, email: string): Observable<unknown> {
    return this.auth.patch_v2(`/permit-request/${requestId}/invite`, {email: email})
      .pipe(
        catchError(this.errors.handleError('Error Sending Permit Invitation'))
      )
  }

  public editPaymentMethodOnRequest(requestId, paymentSource): any {
    return this.auth.patch_v2<any, any>(`/permit-request/${requestId}/payment`, {
      paymentSource: paymentSource
    }).pipe(
      catchError(this.errors.handleError<void>('Error changing Payment Method'))
    )
  }

  public toggleAutoRenewalonRequest(requestIds: string[]): Observable<boolean[]> {
    return forkJoin(requestIds.map((requestId: string) => {
      return this.auth.patch_v2<any, any>(`/permit-request/${requestId}/toggle-auto-renew`, {})
        .pipe(
          catchError(this.errors.handleError('Error toggling auto-renewal on Request', false)),
          map((response) => {
            return response === false ? response : true
          })
        )
    }))
  }

  public makeManualPaymentonRequest(requestId, paymentSource?): any {
    if (paymentSource) {
      return this.auth.patch_v2<any, any>(`/permit-request/${requestId}/manual-payment`, {
        paymentSource: paymentSource
      }).pipe(
        catchError(this.errors.handleError<void>('Error submitting Payment'))
      )
    } else {
      return this.auth.patch_v2<any, any>(`/permit-request/${requestId}/manual-payment`, {}).pipe(
        catchError(this.errors.handleError<void>('Error submitting Payment'))
      )
    }
  }

  public cancelPermitRequest(requestId) {
    return this.auth.patch_v2(`/permit-request/${requestId}/cancel`, {})
      .pipe(
        catchError(this.errors.handleError<void>('Error Canceling Permit Request'))
      )
  }

  public getPermitRequestsbyCustomer(customerId: string) {
    return this.auth.get_v2<PermitRequest>(`/permit-request/${customerId}`)
      .pipe(
        map((result: any) => {
          if (!result) {
            return null;
          }
          return result;
        }),
        catchError(this.errors.handleError('Fetching Permit Requests', null)),
      )
  }

  public getTransactionsbyCustomer(customerId: string) {
    return this.auth.get_v2<PermitTransaction>(`/permit-request/transactions/${customerId}`)
    .pipe(
      map((result: any) => {
        if (!result) {
          return null;
        }
        return result;
      }),
      catchError(this.errors.handleError('Fetching Permit Transactions', null)),
    )
  }

  public getPermitRequestReceipt(id: string, filename: string) {
    if (this.auth.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.auth.accessToken);

      const url = `/permit-request/${id}/receipt/${filename}`
      return this.http.get(environment.API_HOST_V1 + url, {
        headers,
        responseType: 'blob'
      })
        .pipe(
          catchError(this.errors.handleError('Error Downloading Receipt', '')),
        );
    }
    return throwError('No access token');
  }

  public createPermitRequestReceipt(txnId: string): Observable<unknown> {
    const url = `/permit-request/${txnId}/receipt`;
    return this.auth.patch_v1(url, {})
      .pipe(
        switchMap((response: any) => {
          let txn = new PermitTransaction(response);
          if (txn.requestId && txn.receiptFilename) {
            return this.getPermitRequestReceipt(txn.requestId, txn.receiptFilename)
          }
          return of(null)
        })
      );
  }

  public getPermit(id: string) {
    return this.auth.get_v2<Permit>(`/permit/${id}`).pipe(
      catchError(this.errors.handleError('GET Single Permit')),
      map((res: Permit) => new Permit().deserialize(res))
    );
  }

  public getPermitRequestDetails(id: string) {
    return this.http.get(environment.API_HOST_V2 + '/permit-request/' + id + '/details');
  }

  public cancelPermit(id: string, data: Permit) {
    return this.auth.patch_v2<void, Permit>(`/permit/cancel/${id}`, data);
  }

  public cancelIndividualPermit(id: string) {
    return this.auth.patch_v2<void, {}>(`/permit/cancel/${id}`, {})
    .pipe(
      catchError(this.errors.handleError('Error Canceling Permit', false)),
      map(() => true)
    )
  }

  public setPermitToUnassigned(id: string) {
    return this.auth.patch_v2<void, {}>(`/permit/unassign/${id}`, {})
    .pipe(
      catchError(this.errors.handleError('Error Setting Permit to Unassigned', false)),
      map(() => true)
    )
  }

  public getHpsSchedule(id: ObjectId): Observable<Schedule> {
    return this.auth.get_v1(`/hps/schedule/${id}`)
      .pipe(
        catchError(this.errors.handleError('Get Schedule', null)),
        map((results: any) => {
          if (results && results.schedule) {
            return new Schedule(results.schedule);
          }
          return null;
        })
      );
  }

  public createSchedule(id: ObjectId, paymentMethodKey: string, processingDateInfo: number | string) {
    processingDateInfo = (processingDateInfo === 0)
      ? 'First'
      : (processingDateInfo > 27)
        ? 'Last'
        : processingDateInfo;
    return this.auth.post_v2(`/permit/${id}/schedule`, {
      paymentMethodKey,
      processingDateInfo
    })
      .pipe(
        catchError(this.errors.handleError('Save Schedule', null)),
      )
  }

  public getPermitBySignupToken(tokenId: string) {
    return this.auth.patch_v2<Permit, {}>(`/permit/signup-token/${tokenId}`, {})
      .pipe(
        catchError(this.errors.handleError('GET Permit Info', false)),
        map((res: Permit) => res)
      );
  }

  public getPermitTermsFromClient(clientId: ObjectId) {
    return this.auth.get_v2(`/client/${clientId}/terms`)
      .pipe(
        catchError(this.errors.handleError('Get Terms of Service', '')),
        map((res: {toc: string}) => res.toc)
      );
  }

  public updateVehicleOnPermit(permitId: string, data: any) {
    return this.auth.patch_v2(`/permit/${permitId}/vehicle`, data)
      .pipe(
        catchError(() => {
          throw(this.errors.handleError())
        }),
        retryWhen(this.errors.httpRetryStrategy({operation: 'Update Permit Info'}))
      )
  }

  public assignToCustomer(tokenId: string): Observable<Permit> {
    return this.auth.patch_v2(`/permit/signup-token/${tokenId}`, {}).pipe(
      map((res: Permit) => res),
      retryWhen(this.errors.httpRetryStrategy({operation: 'Assign Permit'}))
    )
  };

  public updatePermitPaymentMethod(permitId: string, data: PermitPaymentSourceUpdate) {
    return this.auth.patch_v2<void, PermitPaymentSourceUpdate>(`/permit/${permitId}/source`, data);
  }

  public updateCustomerViaPermit(permitId: string, data: PermitCustomerUpdate) {
    return this.auth.patch_v2<void, PermitCustomerUpdate>(`/permit/${permitId}/customer`, data);
  }

  public getRegionForPermit(): Observable<Region> {
    return new Observable(subscriber => {
      subscriber.next(region);
    }).pipe(
      map((results: GetRegionResults) => {
        if (!results || !results.region) {
          return;
        }
        const feature: Region = new Region({});
        // feature.id = '1234';
        if (results.region && results.region.length) {
          feature.geometry = results.region[0].geometry;
        } else {
          feature.geometry = {
            type: 'Point',
            coordinates: null
          };
        }
        return feature;
      }));
  }
}

const region = {
  region: [
    {
      geometry: {
        'type': 'Polygon',
        'coordinates': [
          [
            [
              -79.54610281452767,
              40.29972341532015
            ],
            [
              -79.54358581705547,
              40.300245435569174
            ],
            [
              -79.54330599837455,
              40.2994334127304
            ],
            [
              -79.5422766729063,
              40.299641166494624
            ],
            [
              -79.54176985501338,
              40.29973821672944
            ],
            [
              -79.541841,
              40.298626
            ],
            [
              -79.54558820620738,
              40.29888089883612
            ],
            [
              -79.54610281452767,
              40.29972341532015
            ]
          ]
        ]
      },
      _id: '57863c12cf604042701a2d15',
      type: 'Feature',
      properties: {
        fill: '#222222',
        zone: '57863c12cf604042701a2d13',
        label: 'Black Zone'
      },
      zone: '57863c12cf604042701a2d13'
    }
  ]
};
