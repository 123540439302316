<div class="container">
  <div class="alert alert-danger" role="alert">
    <h4 class="alert-heading">Oops!</h4>
    <p>{{msg.errorDescription}}</p>
    <hr>
    <div class="form-inline">
      <div class="form-group mx-sm-3">
        <label for="inputPassword2" class="sr-only">Email</label>
        <input type="email" [(ngModel)]="user.email" class="form-control" id="inputPassword2" placeholder="you@example.com">
      </div>
      <button (click)="resend()" class="btn btn-primary">Resend Verification Email!</button>
    </div>
  </div>
</div>
