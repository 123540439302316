export interface IAmount {
    value: number;
    currency: string;
}
export class Amount {
    public static isAmount(arg: any): arg is IAmount {
        return arg instanceof Amount ||
            (arg && typeof arg.value === 'number' )

    }

    public value: number;
    public currency: string;

    constructor(
        initial: number | IAmount,
        currency: string = 'USD'
    ) {
        if (Amount.isAmount(initial)) {
            this.value = initial.value;
            this.currency = initial.currency;
        } else {
            this.value = initial;
            this.currency = currency;
        }
    }

    get formatted(): string {
        return (this.value / 100).toLocaleString('en-US', {
            currency: this.currency,
            style: 'currency'
        });
    }

    get decimal(): number {
        return this.value / 100;
    }
    set decimal(d: number) {
        this.value = d * 100;
    }

    add(v: number | Amount): this {
        if (Amount.isAmount(v)) {
            if (this.currency !== v.currency) {
                throw new Error('Cannot add amounts, currencies do not match')
            } else {
                v = v.value;
            }
        }
        this.value += v;
        return this;
    }

    multiply(v: number): this {
        if (Amount.isAmount(v)) {
            if (this.currency !== v.currency) {
                throw new Error('Cannot add amounts, currencies do not match')
            } else {
                v = v.value;
            }
        }
        this.value *= v;
        return this;
    }

    toString(): string {
        return '' + this.value;
    }
}