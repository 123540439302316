import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as  LogRocket from 'logrocket';

/**
 * Created by danl on 9/6/17.
 */

export interface Token {
  card: {
    number: string;
  };
  card_type: string;
  exp_month: string;
  exp_year: string;
  last_four: string;
  object: string;
  token_expire: Date;
  token_type: string;
  token_value: string;
}

export interface IHeartlandPaymentServiceConfig {
  // Properties:
  url?: string;
  publicKey: string;
}

@Injectable()
export class HeartlandPaymentService {

  private config;
  public static loaded = new Promise(() => { });

  constructor(
    @Inject('HEARTLAND_SERVICE_CONFIG') config: IHeartlandPaymentServiceConfig
  ) {
    this.config = environment.hps;
  }

  createToken(card, cvv, expMonth, expYear): Promise<Token> {
    return new Promise(async (resolve, reject) => {
      const Heartland = await this.global();
      const hps = new Heartland.HPS({
        publicKey: this.config.publicKey,
        cardNumber: this.format(card, 'Card Number'),
        cardExpMonth: this.format(expMonth, 'Card Expiration Month'),
        cardExpYear: this.format(expYear, 'Card Expiration Year'),
        cardCvv: this.format(cvv, 'Card Security Code'),
        success: resolve,
        error: reject
      });
      hps.tokenize()
    })
      .then(response => response as Token);

  }

  format(input: string, hint: string = 'Card Information'): string {
    try {
      return (input).replace(/\D/g, '');
    } catch (_ignore) {
      // Don't send the real error, it may contain sensitive information.
      LogRocket.captureMessage('Issue formatting ' + hint);
    }
    return input;
  }


  async global() {
    await HeartlandPaymentService.loaded;
    return window['Heartland'];
  }
}


