import {Deserializable} from './deserializable';
import {ObjectId, Vehicle as IVehicle} from 'app/interfaces';

export class Vehicle implements Deserializable, IVehicle {
  _id: ObjectId;
  autopay?: boolean;
  deviceId?: string;
  owner?: ObjectId;
  regYear?: string;
  name?: string = '';
  licensePlate: string;
  licenseState: string;
  make?: {
    code: string;
    description: string;
  };
  model?: {
    code: string;
    description: string;
    fulldescription: string;
    make: string;
  };
  color?: {
    code: string;
    description: string;
    hex: string;
    index: number;
    pantone: string;
  };
  year?: number;
  space?: string;

  public deserialize(input: any): Vehicle {
    const vehicle = new Vehicle();
    Object.assign(vehicle, input);
    if (input) {
    vehicle.licenseState = input.licenseState && input.licenseState.toUpperCase();
    }
    return vehicle;
  }

  get registration(): string {
    return [this.licensePlate, this.licenseState].join(', ');
  }

}
