import {Feature, GeoJsonProperties, Point, Polygon, Position} from 'geojson';

export class Region implements Feature<Polygon | Point> {
  id: string | number;
  geometry: Polygon | Point;
  properties: GeoJsonProperties = {};
  type: 'Feature' = 'Feature';


  constructor(
    input: any
  ) {
    this.id = input.id;
    this.geometry = input.geometry;
    this.properties = input.properties;
    this.type = input.type;
  }
  /**
   * Calculate a rectangle that holds all point for the polygon: this.geometry.
   * Returns a Polygon with the SouthWest-most and the NorthEast-most points.
   */
  public boundingRec(): Polygon {

    if (this.geometry.type === 'Point') {
      return {
        type: 'Polygon',
        coordinates: [[
          this.geometry.coordinates,
          this.geometry.coordinates
        ]]
      };
    }

    let n = -90;
    let s = 90;
    let e = -90;
    let w = 90;
    const center: Position = [];

    // Get a rectangle that contains the Polygon:
    this.geometry.coordinates.forEach((poly: Position[]) => {
      poly.forEach((pos: Position) => {
        n = Math.max(n, pos[1]);
        s = Math.min(s, pos[1]);
        e = Math.max(e, pos[0]);
        w = Math.min(w, pos[0]);
      });
    });

    return {
      type: 'Polygon',
      coordinates: [[
        [w, n],
        [e, s]
      ]]
    };
  }

  /**
   * Find the center or the Polygon. Always return the position of a Point.
   */
  public center(): Position {
    switch (this.geometry.type) {
      case 'Point':
        return this.geometry.coordinates;
      case 'Polygon':

        const rec: Polygon = this.boundingRec();
        const ne: Position = rec.coordinates[0][0];
        const sw: Position = rec.coordinates[0][1];

        const center: Position = [];
        center[0] = sw[0] + ((ne[0] - sw[0]) / 2);
        center[1] = sw[1] + ((ne[1] - sw[1]) / 2);

        return center;
    }
  }

  /**
   * Pick a zoom level that will nicely fit the Polygon on a Google Map. Always return 18 for a point. with `mapWidthPx` its the
   * width of the onscreen map, in pixels.
   *
   * @param mapWidthPx
   */
  public zoom(height: number, width: number) {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 18;
    const mapDim = { height, width };

    function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }
    const bounds: Polygon = this.boundingRec();
    const nw = bounds.coordinates[0][0];
    const se = bounds.coordinates[0][1];

    const latFraction = (rad(nw[1]) - rad(se[1])) / Math.PI;

    const lngDiff = se[0] - nw[0];
    const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

    const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  }
}

function rad(lat: number): number {
  const sin: number = Math.sin(lat * Math.PI / 180);
  const radX2: number = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
}
