import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Source} from '../../../../models/source.model';
import {SourceService} from '../../../../service/source/source.service';

@Component({
  selector: 'app-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.scss']
})
export class PaymentNewComponent implements OnInit {

  public editable: boolean = true;

  constructor(
    private notify: ToastrService,
    private sourceService: SourceService,
    private router: Router,
  ) { }

  ngOnInit() {
  }


  onSuccess(source) {
    this.sourceService.changeSource(source);
    this.notify.success('Payment Saved', 'Operation');
    this.router.navigate(['payments-on-file']);
  }

  onCancel() {
    this.router.navigate(['payments-on-file']);
  }

  onError(err) {
    this.notify.error('Error Saving', err.error.message);
  }
}
