import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ach-payment-form',
  templateUrl: './ach-payment-form.component.html',
  styleUrls: ['./ach-payment-form.component.scss']
})
export class AchPaymentFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
