import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import {ErrorComponent} from './error/error.component';
import {FormsModule} from '@angular/forms';
import { ActivatePermitLandingPageComponent } from './activate-permit-landing-page/activate-permit-landing-page.component';

@NgModule({
  imports: [
    FormsModule,
    PagesRoutingModule,
  ],
  declarations: [
    ErrorComponent,
    ActivatePermitLandingPageComponent
  ],
  providers: [
  ]
})
export class PagesModule { }
