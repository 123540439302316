import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {CustomerService} from '../../service/user/customer.service';
import {Router} from '@angular/router';
import { Customer } from 'app/interfaces';


@Component({
  selector: 'app-default-container',
  templateUrl: './default-container.component.html',
  styleUrls: ['./default-container.component.scss']
})
export class DefaultContainerComponent implements OnInit {

  public disabled: boolean = false;
  public status = {isopen: false};
  public customer: Customer = null;

  public loading: Promise<boolean>;
  public year: number;

  constructor(
    public auth: AuthService,
    private userService: CustomerService,
    private router: Router
  ) {
    let date = new Date();
    this.year = date.getFullYear();
  }

  public async ngOnInit() {
    // if no default permissions then assign commuter admin privilege.
    // TODO: make redirect to page asking if they are fleet admin or commuter admin

    if (this.auth.profile.app_metadata.authorization.roles.length === 0) {
      // this.auth.put_v2<String>('/customer/role', 'commuter_admin').subscribe();

      // can't wait for this to be done and have to update the permissions in line
      this.auth.addRole('commuter_admin');
      this.auth.addPermission('commuter:admin');
      this.loading = Promise.resolve(true);
    } else {
      this.loading = Promise.resolve(true);
    }

    this.userService.current
      .subscribe((customer: Customer) => {
        this.customer = customer;
      });
  }

  logout(): void {
    this.auth.logout();
    this.router.navigate(['/']);
  }
}
