import { Injectable } from '@angular/core';
import { PermitRequest } from 'app/models/permit-request.model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../error/error.service';

export interface CardConnectResponseBase {
  respstat: 'A' | 'B' | 'C';
  respcode: string;
  resptext: string;
  respproc: string;
}

export interface ProfileCreateData {
  profile?: string;
  defaultacct?: 'Y';
  profileupdate?: 'Y';
  cofpermission?: 'Y' | 'N';
  auoptout?: 'Y' | 'N';
  accttype?: 'PPAL' | 'PAID' | 'GIFT' | 'PDEBIT';
  merchid: string;
  account?: string;
  bankaba?: string; // Bank routing (ABA) number
  expiry?: string; // MMYY or YYYYMMDD format. Not required for eCheck (ACH) requests
  name?: string;
  address?: string;
  city?: string;
  region?: string;
  country?: string;
  phone?: string;
  postal?: string;
  company?: string;
  email?: string;
}

export interface ProfileResponse {
  profileid: string;
  acctid: string;
  accttype: 'VISA' | 'MC' | 'DISC' | 'ECHK';
  token: string;
  expiry?: string;
  name?: string;
  address?: string;
  city?: string;
  region?: string;
  country?: string;
  postal?: string;
  company?: string;
  defaultacct?: 'Y';
  auoptout?: 'Y' | 'N';
  cofpermission?: 'Y' | 'N';
  gsacard?: string;
  phone?: string;
  email?: string;
}

export interface cardConnectRequest {
  type: string,
  customer?: {
    address: {
      zip: string
    }
    email: string
  },
  fiserv: string,
  card?: {
    expMonth?: string,
    expYear?: string,
    last4?: string,
  }
}

@Injectable()
export class CardConnectService {

  constructor(
    private auth: AuthService,
    private errors: ErrorService,
  ) { }

  public createUpdateProfile(type: string, client: string, permitRequestId: string, data: cardConnectRequest): Observable<Object> {
    return this.auth.put_v2('/customer/cardconnect', {
      tokenData: data,
      type: type,
      clientId: client,
      permitRequestId: permitRequestId
    })
    .pipe(
      catchError(this.errors.handleError('Error Updating Profile'))
    )
  }

  // public getProfile(profileId: string, merchantId: string, accountId?: string): Observable<ProfileResponse> {
  //   return this.auth.get_v2(`${environment.CARD_CONNECT_DOMAIN}/${profileId}/${accountId ? accountId : ''}/${merchantId}`);
  // }
  
}