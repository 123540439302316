
import {HpsDuration, ObjectId, PaymentProvider, Plan as IPlan} from 'app/interfaces';
import {Amount} from 'app/amount';
import {Zone} from './zone.model';
import {Deserializable} from './deserializable';
import { Region } from './Region.model';

export class Plan implements Deserializable, IPlan {
  _id: ObjectId;
  active: boolean;
  availableSpaces: number;
  automaticRenewal: boolean;
  amount: Amount;
  provider: PaymentProvider;
  metadata: any = {};
  name: string;
  createdAt: Date;
  updatedAt: Date;
  interval?: any;
  interval_count?: number;
  planId?: string;
  product?: string;
  frequency?: 'Monthly' | 'Bi-Weekly' | 'Weekly' | 'Semi-Monthly' | 'Quarterly' | 'Semi-Annually' | 'Annually';
  duration?: HpsDuration;
  status: String;
  zone: ObjectId;
  region: Region;
  client: string;
  geometry: {
    latitude: number;
    longitude: number;
  }
  url: string;
  aboutHtml: string;
  instructionsHtml: string;
  images: string[];
  slug: string;
  skus: any;

  deserialize(input): Plan {
    const plan = new Plan();
    Object.assign(plan, input);
    plan.amount = new Amount(input.amount);
    plan.updatedAt = input.updatedAt && new Date(input.updatedAt);
    plan.createdAt = input.createdAt && new Date(input.createdAt);
    if(input.region) {
      plan.region = new Region(input.region)
    }
    return plan;
  }
}
