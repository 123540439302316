import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../../environments/environment';
import {CustomerService} from '../user/customer.service';
import {catchError, debounceTime, map} from 'rxjs/operators';
import {Vehicle} from '../../models/vehicle.model';
import {Observable} from 'rxjs/internal/Observable';
import {ErrorService} from '../error/error.service';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable()
export class VehiclesService {

  public makes: Make[];
  public models: Model[];
  public colors: Color[];
  public make: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private errors: ErrorService,
    private userService: CustomerService
  ) {

  }

  public getColors(search: string = '') {
    if (typeof search === 'string') {
      search = search.toUpperCase();
    }
    return this.http.get<AutocompleteResults>(environment.API_HOST_V1 + '/autocomplete/color?description=' + search)
      .pipe(debounceTime(1000));
  }

  public getMakes(search: string = '') {
    if (typeof search === 'string') {
      search = search.toUpperCase();
    }
    return this.http.get<AutocompleteResults>(environment.API_HOST_V1 + '/autocomplete/make?description=' + search)
      .pipe(debounceTime(1000));
  }

  public getModels(make: Make | string = '', search: string = '') {
    if (make && typeof make !== 'string') {
      make = make.code;
    } else if (typeof make === 'string') {
      make = make.toUpperCase();
    }
    if (typeof search === 'string') {
      search = search.toUpperCase();
    }
    return this.http.get<AutocompleteResults>(environment.API_HOST_V1 + '/autocomplete/model?make=' +
      make + '&description=' + search)
      .pipe(debounceTime(1000));
  }

  public getAutocomplete(search: string = '', type: 'make'|'model'|'color') {
    let ob$: Observable<AutocompleteResults>;
    switch (type) {
      case 'make':
        ob$ = this.getMakes(search);
        break;
      case 'model':
        ob$ = this.getModels(this.make, search);
        break;
      case 'color':
        ob$ = this.getColors(search);
        break;
    }

    return ob$
      .pipe(
        catchError(this.errors.handleError('Autocomplete', {matches: []}))
      )
      .subscribe((results: AutocompleteResults) => {
        const matches = results.matches.sort((a: any, b: any) => a.description < b.description ? -1 : 1);
        switch (type) {
          case 'make':
            this.makes = matches;
            break;
          case 'model':
            this.models = matches;
            break;
          case 'color':
            this.colors = matches;
            break;
        }
      });
  }

  updateCustomerVehicle(vehicle: Vehicle) {
    return this.auth.put_v2<Vehicle>('/customer/vehicle', vehicle)
      .pipe(
        catchError(this.errors.handleError('Save Vehicles', false)),
      );
  }

  saveCustomerVehicle(vehicle) {
    return this.auth.post_v2<void, Vehicle>('/customer/vehicle', vehicle)
      .pipe(
        catchError(this.errors.handleError('Save Vehicles'))
      );
  }

  deleteCustomerVehicle(vehicleId) {
    return this.auth.delete_v2(`/customer/vehicle/${vehicleId}`)
      .pipe(
        catchError(this.errors.handleError('Delete Vehicles'))
      )
  }
}

export interface AutocompleteResults {
  matches: any[];
}
export interface Color {
  code: string;
  description: string;
  hex: string;
  index: number;
  pantone: string;
}

export interface Make {
  code: string;
  description: string;
}

export interface Model {
  code: string;
  description: string;
  fulldescription: string;
  make: string;
}

export interface AutocompleteResults {
  matches: any[];
}
export interface Color {
  code: string;
  description: string;
  hex: string;
  index: number;
  pantone: string;
}

export interface Make {
  code: string;
  description: string;
}

export interface Model {
  code: string;
  description: string;
  fulldescription: string;
  make: string;
}
