import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router, ActivatedRoute, NavigationEnd, Event} from '@angular/router';
import {AppBreadcrumbService} from './breadcrumb.service';
import {DOCUMENT} from '@angular/common';


@Component({
  selector: 'breadcrumbs',
  template: `
    <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs | async" let-last = last>
      <li class="breadcrumb-item"
          *ngIf="breadcrumb.label.title && (breadcrumb.url.slice(-1) == '/' || last)"
          [ngClass]="{active: last}">
        <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</a>
        <span *ngIf="last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</span>
      </li>
    </ng-template>
  `
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  @Input() fixed: boolean;
  public breadcrumbs;
  private readonly fixedClass = 'breadcrumb-fixed';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    public service: AppBreadcrumbService,
    public el: ElementRef
  ) { }

  public ngOnInit(): void {
    try {
      Replace(this.el);
      this.isFixed(this.fixed);
      this.breadcrumbs = this.service.breadcrumbs;
    } catch (e) {
      console.error(e);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, this.fixedClass);
  }

  isFixed(fixed: boolean = this.fixed): void {
    if (fixed) {
      this.renderer.addClass(this.document.body, this.fixedClass);
    }
  }
}


export function Replace(el: any): any {
  const nativeElement: HTMLElement = el.nativeElement;
  const parentElement: HTMLElement = nativeElement.parentElement;
  // move all children out of the element
  while (nativeElement.firstChild) {
    parentElement.insertBefore(nativeElement.firstChild, nativeElement);
  }
  // remove the empty element(the host)
  parentElement.removeChild(nativeElement);
}
