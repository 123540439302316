import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../../service/auth/auth.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public msg: Params;
  public user: any;

  constructor(
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    this.user = {email: ''};
  }

  ngOnInit() {

    this.route
      .params
      .subscribe(msg => this.msg = msg);
  }

  resend() {
    // todo: what user?
    this.auth.resendVerificationEmail(this.user.email)
      .subscribe(
      (response) => {
        alert('Sent!');
      },
      (err) => {
        alert(`${err.statusText}: ${err.message}`);
      });
  }

}
