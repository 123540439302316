import {Deserializable} from './deserializable';
import * as moment from 'moment';
import {ObjectId} from 'app/interfaces';

export class HpsSource {
  accountNumberLast4: string;
  addressLine1: string;
  addressLine2?: string;
  cardBrand: string;
  city: string;
  company?: string;
  country: 'USA' | 'CAD';
  customerIdentifier: ObjectId;
  customerKey: number;
  customerStatus: string;
  expirationDate: Date; // "1225"
  firstName: string;
  hasActiveSchedules: boolean;
  hasSchedules: boolean;
  lastChangeDate: Date; // "12/2/2020 12:20:16 PM"
  lastName: string;
  nameOnAccount: string;
  paymentMethod: string;
  paymentMethodIdentifier: ObjectId;
  paymentMethodKey: string;
  paymentMethodType: string;
  paymentStatus: string;
  preferredPayment: boolean;
  stateProvince: string;
  statusSetDate: Date; // "2020-12-02T12:20:16.783"
  zipPostalCode: string;
  constructor(input: any) {
    this.accountNumberLast4 = input.accountNumberLast4;
    this.addressLine1 = input.addressLine1;
    this.addressLine2 = input.addressLine2;
    this.cardBrand = input.cardBrand;
    this.city = input.city;
    this.company = input.company;
    this.country = input.country;
    this.customerKey = input.customerKey;
    this.firstName = input.firstName;
    this.hasActiveSchedules = input.hasActiveSchedules;
    this.hasSchedules = input.hasSchedules;
    this.lastChangeDate = new Date(input.lastChangeDate);
    this.lastName = input.lastName;
    this.nameOnAccount = input.nameOnAccount;
    this.paymentMethod = input.paymentMethod;
    this.paymentMethodIdentifier = input.paymentMethodIdentifier;
    this.paymentMethodKey = input.paymentMethodKey;
    this.paymentMethodType = input.paymentMethodType;
    this.paymentStatus = input.paymentStatus;
    this.preferredPayment = input.preferredPayment;
    this.stateProvince = input.stateProvince;
    this.statusSetDate = new Date(input.statusSetDate);
    this.zipPostalCode = input.zipPostalCode;

    if (input.expirationDate) {
      const month = +input.expirationDate.substr(0, 2) - 1;
      const year = +input.expirationDate.substr(2, 2) + 2000;
      const date = new Date();
      date.setMonth(month);
      date.setFullYear(year);
      this.expirationDate = date;
    }
  }
  fullname() {
    return [this.firstName, this.lastName].join(' ');
  }
  expiration() {
    const exp = moment(this.expirationDate).startOf('month');
    return exp.format('MM/YY');
  }
}

export class Source implements Deserializable {
  _id: string;
  gateway: string;
  address: {
    line1: string;
    line2?: string;
    line3?: string;
    city: string;
    state: string;
    zip: string;
    country: 'USA' | 'CAD'
  };
  name: {
    first: string;
    last: string;
  };
  country: string;
  creation_date: Date;
  exp_year: number;
  exp_month: number;
  funding: string; // credit, debit
  preferredPayment: boolean;
  brand: string; // Visa, Amex, MC, Discover
  last4: string;
  token: string;
  primaryEmail: string;

  fullname() {
    return [this.name.first, this.name.last].join(' ');
  }

  expiration() {
    const exp = moment();
    exp.year(this.exp_year).month(this.exp_month).startOf('month');

    return exp.format('MM/YY');

  }

  public deserialize(input: any) {
    const source = new Source();
    const exp = input.expirationDate.split('');
    source._id = input.paymentMethodIdentifier;
    source.gateway = 'hps';
    source.address = {
      country: input.country,
      zip: input.zipPostalCode,
      state: input.stateProvince,
      city: input.city,
      line1: input.addressLine1,
      line2: input.address_line2,
    };
    source.name = {
      first: input.firstName,
      last: input.lastName,
    };
    source.country = input.country;
    source.creation_date = new Date(input.creationDate);
    source.exp_year = exp[2] + exp[3];
    source.exp_month = parseInt(exp[0] + exp[1], 10) - 1;
    source.funding = input.paymentMethodType;
    source.preferredPayment = input.preferredPayment;
    source.brand = input.paymentMethod.split(' ')[0];
    source.last4 = input.accountNumberLast4;
    source.token = input.paymentMethodKey;
    return source;
  }
}
