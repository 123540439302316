import {ObjectId} from 'app/interfaces';
import {User} from './user.model';
import {Vehicle} from './vehicle.model';
import {Ledger} from './ledger.model';
import {Plan} from './plan.model';
import {PlanSku} from './plansku.model';

export enum PermitStatus {
  Created = 'Created',
  Pending = 'Pending',
  Requested = 'Requested',
  Active = 'Active',
  PastDue = 'PastDue',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Inactive = 'Inactive',
  CustomerActionRequired = 'Customer Action Required'
}

export class Name {
  get full(): string {
    return [this.first, this.last].join(' ');
  }
  first = '';
  last = '';

  deserialize(name): this {
    const o = new Name();
    return Object.assign(o, name);
  }
}

export class Permit {
  name?: Name;
  _id: ObjectId;
  status: PermitStatus;
  client: ObjectId;
  vehicle: Vehicle;
  subscription: string;
  ledger: Ledger[];
  cancellationDate?: Date;
  createdAt: Date;
  buyer: ObjectId;
  customer: ObjectId | User;
  invite: ObjectId | User;
  permitNumber: string;
  permitType: string;
  autoRenew: boolean;
  start: Date;
  startDate: Date;
  end: Date;
  readonly editable: boolean;
  space: string;
  acceptedTermsMF: boolean;
  acceptedTermsClient: boolean;
  setupComplete: boolean;
  signUpToken: string;
  payment: any;
  plan: Plan;
  sku: PlanSku;
  processingDateInfo: number | 'First' | 'Last';
  numberOfPaymentsRemaining: number;
  endDate: Date;
  updatedAt: Date;
  fullName: string;
  company?: string;
  email?: string;
  phone?: string;
  address: {
    line1?: string;
    line2?: string;
    line3?: string;
    city?: string;
    state?: string;
    zip: string;
    country: 'USA' | 'CAD';
  }
  searchData: {
    lastPayment: Date,
    nextPayment: Date,
    planName: string,
  }
  events: any;

  get currentLedger(): Ledger {
    return this.ledger[this.ledger.length - 1];
  }

  public deserialize(input: any): Permit {
    const permit = new Permit();
    Object.assign(permit, input);
  
    if (input.customer) {
      if (typeof input.customer !== 'string') {
        permit.customer = new User().deserialize(input.customer);
      }
    }
    if (input.vehicle) {
      permit.vehicle = new Vehicle().deserialize(input.vehicle);
    }
    if (input.ledgers && input.ledgers.length) {
      permit.ledger = input.ledgers.map((l: any) => {
        return new Ledger().deserialize(l);
      });
    }
    if (input.plan && typeof input.plan !== 'string') {
      permit.plan = new Plan().deserialize(input.plan);
    }
    permit.start = input.start ? new Date(input.start) : new Date();
    permit.end = input.end ? new Date(input.end) : undefined;
    permit.endDate = input.endDate ? new Date(input.endDate) : undefined;
    permit.createdAt = new Date(input.createdAt);
    permit.updatedAt = new Date(input.updatedAt);

    permit.fullName = new Name().deserialize(input.name).full;

    return permit;
  }

}
