import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import {HeartlandPaymentService, Token} from '../../../../service/hps/hps.service';
import {CreateSourceRequest, SourceService} from '../../../../service/source/source.service';
import {CustomerService} from '../../../../service/user/customer.service';
import {Customer} from 'app/interfaces';
import * as LogRocket from 'logrocket';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit, AfterViewInit {
  @Output() success = new EventEmitter<any>();

  @Output() error = new EventEmitter<Error>();

  @Output() cancel = new EventEmitter<void>();

  @Input() label: string;

  private customer: Customer;

  public form: FormGroup;

  errors: {
    number: string;
    expiration: string;
    cvc: string;
  };

  // Form Controls:
  public name_first: AbstractControl;
  public name_last: AbstractControl;
  public address_country: AbstractControl;
  public address_zip: AbstractControl;
  public address_state: AbstractControl;
  public address_city: AbstractControl;
  public address_line1: AbstractControl;
  public address_line2: AbstractControl;
  public ccnumber: AbstractControl;
  public ccexp: AbstractControl;
  public cvv: AbstractControl;
  public email: AbstractControl;

  constructor(
    public fb: FormBuilder,
    private heartland: HeartlandPaymentService,
    private paymentsService: SourceService,
    private userService: CustomerService
  ) {

    this.form = fb.group({
      name_first: ['', Validators.required],
      name_last: ['', Validators.required],
      address_line1: ['', Validators.required],
      address_line2: [],
      address_city: ['', Validators.required],
      address_state: ['', Validators.required],
      address_zip: ['', Validators.required],
      address_country: ['USA', Validators.required],
      ccnumber: ['', Validators.required],
      ccexp: ['', Validators.required],
      cvv: ['', Validators.required],
      email: ['', Validators.required],
    });

    this.name_first = this.form.controls['name_first'];
    this.name_last = this.form.controls['name_last'];
    this.address_line1 = this.form.controls['address_line1'];
    this.address_line2 = this.form.controls['address_line2'];
    this.address_city = this.form.controls['address_city'];
    this.address_zip = this.form.controls['address_zip'];
    this.address_state = this.form.controls['address_state'];
    this.address_country = this.form.controls['address_country'];
    this.ccnumber = this.form.controls['ccnumber'];
    this.ccexp = this.form.controls['ccexp'];
    this.cvv = this.form.controls['cvv'];
    this.email = this.form.controls['email'];

  }

  async ngAfterViewInit() {
    this.userService.fetchCustomer().subscribe((res) => {
      this.customer = res;
      this.email.setValue(this.customer.email);
    });
    const Heartland = await this.heartland.global();
    Heartland.Card.attachNumberEvents('#ccnumber');
    Heartland.Card.attachExpirationEvents('#ccexp');
    Heartland.Card.attachCvvEvents('#cvv');
  }

  ngOnInit() {}

  onCancel(): void {
    this.cancel.emit();
  }

  onSaveSource() {
    if (this.form.valid) {
      // Tokenize Card:
      const params = this.form.value;
      let year;
      let month;
      try {
        const split = params.ccexp.split('/');
        if (!split || split.length != 2) {
          LogRocket.captureException(new Error('Exp data not formatted correctly: ' + params.ccexp));
        }
        month = split[0].replace(/^\s+|\s+$/g, '');
        year  = split[1].replace(/^\s+|\s+$/g, '');

        // The Heartland Card Expiration Events should expands the year to 4 digits
        // But unless the user enters all 4 numbers we only get 2.
        // #Y3K Bug
        if (year.length === 2) {
          year = '20' + year;
        }
      } catch (err) {
        LogRocket.captureException(err);
        throw new Error('Issue with expiration date, expected: MM/YYYY');
      }

      return this.heartland.createToken(params.ccnumber, params.cvv, month, year)
        .then((token: Token) => {
          const request: CreateSourceRequest = {
            firstName: params.name_first,
            lastName: params.name_last,
            nameOnAccount: [params.name_first, params.name_last].join(' '),
            addressLine1: params.address_line1,
            addressLine2: params.address_line2 || undefined,
            city: params.address_city,
            stateProvince: params.address_state.toUpperCase(),
            zipPostalCode: params.address_zip,
            country: (params.addres_country === 'CAD') ? 'CAD' : 'USA',
            expMon: +token.exp_month,
            expYear: +token.exp_year,
            paymentToken: token.token_value,
            primaryEmail: params.email
          };
          this.paymentsService.saveSource(request).subscribe(res => this.success.emit(res));
          return request;
        });
    }
  }

  get number()          { return this.form.controls.ccnumber; }
  get expiration()      { return this.form.controls.ccexp; }
  get cvc()             { return this.form.controls.cvv; }
}
