import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HpsSource } from '../../../models/source.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'environments/environment';
import { PermitsService } from 'app/service/permit/permits.service';
import { CustomerService } from 'app/service/user/customer.service';
import { Customer, TicketStates } from 'app/interfaces';
import { CardConnectService } from 'app/service/cardconnect/cardconnect.service';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { DateTime, DateTimeUnit } from 'luxon';

@Component({
  templateUrl: 'payments.component.html',
  styleUrls: ['payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  public formattedRequests = [];
  public loading = true;
  public noRequests = false;
  public filteredRequests = [];

  public editable: boolean = true;
  public sources: HpsSource[];
  public deleteSource: HpsSource;
  public preferred: HpsSource;

  public paymentProcessing: boolean = false;

  public deleteModalRef: BsModalRef;
  public modalConfig = {
    keyboard: true
  };

  public ccurl: string = environment.cardConnectEndpoint;

  public viewingMoreInformation: boolean;

  constructor(
    public permitService: PermitsService,
    public customerService: CustomerService,
    public cardConnectService: CardConnectService,
    public notify: ToastrService,
  ) {
  }

  ngOnInit() {
    this.customerService.fetchCustomer()
      .subscribe((customer: Customer) => {

        this.permitService.getPermitRequestsbyCustomer(customer._id)
          .subscribe((requests) => {
            if (requests) {

              this.filteredRequests = requests.filter(request => {
                return this.filterRequest(request);
              })

              if (this.filteredRequests.length) {
                this.formatRequests(this.filteredRequests);
              } else {
                this.noRequests = true;
                this.loading = false;
              }
            }
          })
      })
  }

  public formatRequests(requests) {
    requests.forEach(permitRequest => {
      this.formattedRequests.push({
        _id: permitRequest._id,
        number: permitRequest.numberOfPermits,
        plan: permitRequest.plan.name,
        requestDate: permitRequest.createdAt,
        status: permitRequest.status,
        timeSlot: permitRequest.sku.name,
        updatedAt: permitRequest.updatedAt,
        paymentMethod: permitRequest.paymentSource.paymentMethodType === 'Credit Card' ? `Card ****${permitRequest.paymentSource.accountNumberLast4}` : `ECheck **** ${permitRequest.paymentSource.accountNumberLast4}`,
        cost: permitRequest.sku.totalCost.value / 100,
        autoRenew: permitRequest.autoRenew,
        endDate: permitRequest.endDate,
        lateFee: this.calculateLateFee(permitRequest)
      })
    })
    this.loading = false;
  }

  public calculateLateFee(request) {
    let calculatedAdjustment = 0;
    if (request.escalation && request.escalation.adjustments && request.escalation.adjustments.length) {
      request.escalation.adjustments.forEach(adjustment => {
        if (adjustment.event === 'permit.request.late.fee.applied') {
          if (new Date() > this.addToDate(request.nextPayment, request.plan.timezone ? request.plan.timezone : 'America/New_York', adjustment.dayCount, 'days')) {
            calculatedAdjustment = adjustment.totalFee.value;
          }
        }
      })
    }
    return (calculatedAdjustment / 100);
  }

  public addToDate(base: Date, timezone: string, count: number, type: 'days' | 'hours'): Date {
    if (typeof base === 'string') {
      base = new Date(base);
    }
    let endOfDate: DateTimeUnit = type === 'days' ? 'day' : 'hour';
    return DateTime.fromJSDate(base, {zone: timezone }).plus({ [type]: count }).endOf(endOfDate).toJSDate();
    // return moment(base).add(count, type).endOf(type).toDate();
  }

  public handleEditPaymentMethod(evt: Event): void {
    this.editPaymentMethodOnRequest(evt);
  }

  public handleMakePayment(evt: any): void {
    this.paymentProcessing = true;
    if (evt.token) {
      this.makeManualPaymentWithToken(evt);
    } else {
      this.makeManualPaymentOnRequest(evt);
    }
  }

  public handleToggleAutoRenewal(evt: any): void {
    this.toggleAutoRenewalOnRequest(evt);
  }

  public toggleAutoRenewalOnRequest(requestIds: { ids: string[] }) {
    this.permitService.toggleAutoRenewalonRequest(requestIds.ids)
      .pipe(
        catchError((request) => {
          this.notify.error('Error Toggling Auto Renewal');
          throw request;
        })
      ).subscribe((res) => {
        this.notify.success('Success', 'Request Auto Renewal Updated');
        location.reload();
      })
  }


  public editPaymentMethodOnRequest(request) {
    this.permitService.editPaymentMethodOnRequest(request.request, request.token)
      .pipe(
        catchError((request) => {
          this.notify.error('Error Updating Payment Method');
          throw request;
        })
      ).subscribe((res) => {
        this.notify.success('Success', 'Payment Method Updated');
        location.reload()
      })
  }

  public makeManualPaymentOnRequest(request) {
    this.permitService.makeManualPaymentonRequest(request.request)
      .pipe(
        catchError((request) => {
          this.paymentProcessing = false;
          this.notify.error('Error submitting Payment');
          throw request;
        })
      ).subscribe((res) => {
        this.notify.success('Success', 'Payment Submitted');
        location.reload()
      })
  }

  public makeManualPaymentWithToken(request) {
    this.permitService.makeManualPaymentonRequest(request.request, request.token)
      .pipe(
        catchError((request) => {
          this.paymentProcessing = false;
          this.notify.error('Error submitting Payment');
          throw request;
        })
      ).subscribe((res) => {
        this.notify.success('Success', 'Payment Submitted');
        location.reload()
      })
  }

  public filterRequest(request) {
    if (request.status === 'Approved' || request.status === 'Customer Action Required' || request.status === 'Past Due') {
      if (request.sku.rate.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public isWithinPaymentProcessingWindow() {
    const currentDate = moment();
    const startOfMonth = moment().date(1).startOf('day');
    const endOfTwelveHours = moment().date(1).startOf('day').add(12, 'hours');
    return currentDate.isBetween(startOfMonth, endOfTwelveHours);
  }

}

