import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentsComponent } from './payments.component';
import {PaymentViewComponent} from './payment-view/payment-view.component';
import {PaymentNewComponent} from './payment-new/payment-new.component';


const routes: Routes = [
  {
    path: '',
    component: PaymentsComponent,
    data: {
      title: 'Payment Information'
    },
    children: [
    {
      path: 'new',
      component: PaymentNewComponent,
      data: {
        title: 'New Payment'
      }
    }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule {}
