import {Deserializable} from './deserializable';
import {Vehicle} from './vehicle.model';
import {Source} from './source.model';

export class User implements Deserializable {
  _id: string;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
  auth0: {
    sub: string;
  };
  company: string;
  created: Date;
  default_source: Source;
  email: string;
  hpsCustomerKey: string;
  name: {
    first: string;
    last: string;
  };
  phone: string;
  pushToken: string;
  sources: Source[];
  vehicles: Vehicle[];

  fullname(): string {
    return [this.name.first, this.name.last].join(' ');
  }

  public deserialize(input: any): User {
    const user = new User();
    Object.assign(user, input);

    user.name = input.name || {first: '', last: ''};

    return user;
  }
}
