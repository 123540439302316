import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {mergeMap} from 'rxjs/operators';
import {timer} from 'rxjs/internal/observable/timer';

export interface IErrorDescription {

}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private notify: ToastrService
  ) {}

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T = IErrorDescription>(operation = 'Operation', result?: T) {
    return (context: any): Observable<T> => {
      
      const res = switchError(context, operation);

      this.notify.error(res.message, `${operation} Failed`);
      
      if (result !== undefined) {
        return of(result);
      } else {
        return throwError(res);
      }
    };
  }

  public extractAndReturnError<T>(operation = 'Operation') {
    return (context: any): string[] => {

      const res = switchError(context, operation);

      return [res.message, res.details];
    }
  }

  public httpRetryStrategy(
    {
      maxRetryAttempts = 3,
      scalingDuration = 1000,
      includedStatusCodes = [504, 408, 502],
      operation = 'Grab Info',
    }: {
      maxRetryAttempts?: number;
      scalingDuration?: number;
      includedStatusCodes?: number[];
      operation?: string;
  } = {}) {
    return (attempts: Observable<any>) => {
      return attempts.pipe(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          // if maximum number of retries have been met
          // or response is a status code we did not explicitly say to retry, throw error
          if (retryAttempt > maxRetryAttempts || !includedStatusCodes.includes(error.status)) {
            this.notify.error('Unable to Connect', `${operation} Failed`);
            return throwError(error);
          }
          this.notify.warning(`Retrying ${operation}. Attempt: ${retryAttempt} out of ${maxRetryAttempts}`, `${operation} Failed`);
          // retry after 1s, 2s, etc...
          return timer(retryAttempt * scalingDuration);
        })
      );
    };
  }
}

export function isError(subject: any): subject is Error {
  return subject instanceof Error;
}

export function isHttpErrorResponse(subject: any): subject is HttpErrorResponse {
  return subject && subject.name === 'HttpErrorResponse';
}

export function isProgressEvent(subject: any): subject is ProgressEvent {
  return subject && subject instanceof ProgressEvent;
}

function switchError(context: any, operation: string) {
  let message: string;
  let details: any = {};
  if (isHttpErrorResponse(context)) {
    details = {
      req_id: context.headers.get('Request-Id'), // Retain spelling from restify logger, server side
      subject: `Admin error, ${operation} Failed, ${context.statusText}`,
      url: context.url,
    };
    if (isProgressEvent(context.error) || context.error === null) {
      message = context.message;
      details.error = new Error(message);
    } else if (context.error && context.error.error) {
      message = context.error.error.message;
      details.error = context.error.error;
    } else {
      message = 'Unexpected Error';
      details.error = new Error(message);
    }
  } else if (isError(context)) {
    message = context.message;
    details = {
      error: context,
      subject: `Admin error, ${message}`
    };
  } else if (isError(context.error)) {
    message = context.error.message;
    details = {
      error: context.error,
      subject: `Admin error, ${message}`
    };
  } else if (typeof context === 'string') {
    message = context;
    details = {
      error: new Error(context),
      subject: `Admin error, ${message}`
    };
  } else {
    message = 'Unknown Error';
    details = {
      error: new Error(message),
      subject: `Admin error, ${message}`
    };
  }

  if (message.match(/Http failure response/)) {
    message = 'API Endpoint Unreachable';
  }
  details.error = JSON.parse(JSON.stringify(details.error)); // Strip out circular dependency.
  return {message, details}
}

