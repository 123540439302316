// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API_HOST_V1: 'https://api.dev.meterfeeder.com',
  API_HOST_V2: 'https://api.dev.meterfeeder.com',

  facebook: {
    clientId: '166058417287606',
    redirectUri: 'http://localhost:8000/auth/facebook'
  },
  googleMapsKey: 'AIzaSyAsvDJru987bH6Wx2b6EFdnbaOTct2R1L4',
  cardConnectEndpoint: 'https://boltgw-uat.cardconnect.com',
  hps: {
    publicKey: 'pkapi_cert_oLzEg7v3NqX5XujUEv',
    url: ''
  },
  version: '2.13.0-uat',
  auth0: {
    domain: 'mfdev.auth0.com',
    clientID: 'AQesUzSy1GfckrZGDobbBwY61maL624s',
    namespace: 'https://meterfeeder.com/',
    responseType: 'token',
    scope: 'openid email profile',
    audience: 'http://develop.test.meterfeeederapp.com',
    redirectUri: window.location.origin + '/callback',
  },
};
