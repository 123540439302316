import {HttpParams} from '@angular/common/http';
import {Params} from '@angular/router';
import {Moment} from 'moment';
import 'datatables.net';
import ColumnSettings = DataTables.ColumnSettings;
import * as moment from 'moment';

export interface DateRangeEvent {
  end: Moment;
  label: string;
  start: Moment;
}

export interface DataTableColumn {
  data: string;
  name: string;
  orderable: boolean;
  search: DataTableSearch;
  searchable: boolean;
}

export interface DataTableQueryParams {
  columns?: ColumnSettings[];
  draw?: number;
  end?: Moment;
  length?: number;
  order?: DataTableSortOrder[];
  search?: DataTableSearch;
  start?: Moment;
  state?: string;
  time?: number | Date;
  zone?: string;
}

export interface DataTableResults {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

export interface DataTableSearch {
  value: string;
  regex: boolean;
  caseInsensitive?: boolean;
  search?: string;
  smart: boolean;
}

export interface DataTableSortOrder {
  column: number;
  dir: string;
}

export class DataTableService {
  public urlParamsToDataTable(settings: DataTables.Settings, urlparams: Params): DataTableQueryParams {
    const params: DataTableQueryParams = {
      columns: settings.columns
    };
    if (urlparams) {
      // Load user chosen params:
      if (urlparams.length) {
        try {
          params.length = parseInt(urlparams.length, 10);
        } catch (e) {
          // ignore malformed number ... just log it
        }
      }
      if (urlparams.order) {
        try {
          params.order = JSON.parse(urlparams.order);
        } catch (e) {
          // ignore malformed json  ... just log it
        }
      }
      if (urlparams.search) {
        try {
          params.search = JSON.parse(urlparams.search);
        } catch (e) {
          // ignore malformed json  ... just log it
        }
      }
      if (urlparams.page) {
        // Unsure how to reproduce the `after` query params
        // page = urlparams.page;
      }
      if (urlparams.time) {
        try {
          const time = parseInt(urlparams.time, 10);
          params.time = new Date(time);
        } catch (e) {
          // ignore malformed number ... just log it
        }
      }

      if (urlparams.start) {
        params.start = moment(urlparams.start);
      }
      if (urlparams.end) {
        params.end = moment(urlparams.end);
      }
      return params;
    }
  }
  public getStartingPoint<T>(params: DataTableQueryParams, collection: T[]): string {
    let after: any;
    // Save the spot to start this query from.
    const last = collection[collection.length - 1];
    if (last) {
      const idx = params.order[0].column;
      const sort = params.columns[idx].data.toString();
      after = sort.split('.').reduce((o, i) => {
        return o[i];
      }, last);
      if (after instanceof Date) {
        after = after.toISOString();
      }
    }
    return after;
  }
  public paramsToQuery(params: DataTableQueryParams, after?: string) {
    let data: HttpParams = new HttpParams();

    if (params.length) {
      data = data.set('limit', params.length.toString());
    }
    if (params.search) {
      if (!!params.search.value) {
        data = data.set('q', params.search.value)
          .set('qregex', params.search.regex + '');
      } else if (!!params.search.search) {
        data = data.set('q', params.search.search)
          .set('qregex', params.search.regex + '');
      }
    }
    if (params.order && params.order.length) {
      let idx = params.order[0].column;
      let order = params.order[0].dir;

      if (Array.isArray(params.order[0])) {
        idx = params.order[0][0];
        order = params.order[0][1];
      }
      const sort = params.columns[idx].data.toString();

      data = data.set('sort', sort)
        .set('order', order);

      if (after) {
        data = data.set('after', after);
      }
    }
    if (params.zone) {
      data = data.set('zone', params.zone);
    }
    if (params.state) {
      data = data.set('state', params.state);
    }
    if (params.start) {
      data = data.set('start', params.start.toISOString());
    }
    if (params.end) {
      data = data.set('end', params.end.toISOString());
    }
    return data;
  }
}
