import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Customer } from 'app/interfaces';
import { PermitRequest } from 'app/models/permit-request.model';
import { Plan } from 'app/models/plan.model';
import { LocationService } from 'app/service/location/location.service';
import { PermitsService } from 'app/service/permit/permits.service';
import { CustomerService } from 'app/service/user/customer.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CardConnectService } from 'app/service/cardconnect/cardconnect.service';
import { AuthService } from 'app/service/auth/auth.service';
import { SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Component({
  selector: 'app-request-form',
  template: `
    <div class="animated fadeIn" style="margin: 10%; margin-top: 20px;">
      <div class="row">
        <div class="col-12 col-md-7">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <div class="row" *ngIf="formattedPlan && selectedSku && customer">
                <div class="col" style="overflow-wrap: break-word">
                  <lib-permit-request-form
                    [plan]="formattedPlan"
                    [selectedSku]="selectedSku"
                    [allowACH]="allowACH"
                    [cardConnectUrl]="ccurl"
                    [customerEmail]="customer.email"
                    (onSubmitForm)="handleRequestSubmit($event)"
                    (onSelectTimeSlot)="handleSelectTimeSlot($event)"
                    (onSwitchUser)="handleSwitchUser($event)"
                  ></lib-permit-request-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5 d-sm-block d-none">
          <img src="assets/img/MeterFeeder-Next-Steps.png" alt="Permit Request Next Steps" width="100%">
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class RequestFormComponent implements OnInit {

  public selectedSku: string;
  public ccurl: string = environment.cardConnectEndpoint;
  public formattedPlan;
  public formattedSkus = [];
  public customer: Customer;
  public permitsCount: number;
  public plan;
  public client;
  public termsOfServiceHtml?: SafeHtml;
  public allowACH: boolean;

  constructor(
    public locationService: LocationService,
    private route: ActivatedRoute,
    public customerService: CustomerService,
    public permitService: PermitsService,
    public notify: ToastrService,
    public router: Router,
    public cardConnectService: CardConnectService,
    public auth: AuthService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    const params: Params = this.route.snapshot.params;
    const planSlug = params['planslug'];
    const skuId = params['id'];
    if (!planSlug || !skuId) {
      // Route to the search page, cause we can't load.
      return
    }
    this.selectedSku = skuId;
    this.locationService.getPlanById(planSlug)
      .subscribe((plan: Plan) => {
        this.plan = plan;
        this.customerService.fetchClient(this.plan.client).pipe(
          switchMap((client) => {
            // Turn the relative link to the TOS asset into the HTML.
            if (client?.terms?.asset) {

              return this.http.get(client.terms.asset, {
                  responseType: 'text'
              }).pipe(
                map((html: string) => {
                  client.terms.asset = html;
                  return client;
                })
              )
            } else {
              return of(client);
            }
          })
        )
          .subscribe((client) => {
            this.client = client;
            this.allowACH = client.allowACH;
            this.formatPlan(this.plan);
          })
      });
    this.customerService.fetchCustomer()
      .subscribe((customer: Customer) => {
        this.customer = customer;
      })

  }

  public handleRequestSubmit(evt: Event): void {
    this.submitPermitRequest(evt)
  }

  public formatPlan(plan) {
    plan.skus.forEach(sku => {
      this.formattedSkus.push({
        ...sku,
        timeSlot: sku.name,
        uniqueLabel: sku.name,
        inventory: this.calculateAvailableSpaces(sku.inventory, plan.permits)
      });
    })

    this.formattedPlan = {
      ...plan,
      skus: this.formattedSkus,
      terms: this.client.terms,
    }
  }

  public handleSelectTimeSlot(evt: Event): void {
    this.router.navigate([`location/${this.route.snapshot.url[1].path}`]);
  }

  public handleSwitchUser(evt: Event): void {
    // save route to local storage and logout
    let current = window.location.pathname + window.location.search;
    localStorage.setItem('redirectUrl', current);
    this.auth.logout();
  }

  public submitPermitRequest(request) {
    const permitRequest: PermitRequest = {
      name: {
        first: request.firstName ? request.firstName : undefined,
        last: request.lastName ? request.lastName: undefined
      },
      email: request.email,
      company: request.company ? request.company : undefined,
      customer: this.customer._id ? this.customer._id : undefined,
      paymentToken: request.paymentToken,
      client: request.timeSlot.client,
      plan: request.timeSlot.plan,
      sku: request.timeSlot._id,
      numberOfPermits: request.numberOfPermits,
      status: 'Requested',
      startDate: request.startDate,
      waitlist: request.timeSlot.inventory - this.plan.permits ? false : true,
      type: request.typeOfPermit,
      autoRenew: request.autoRenew,
      files: request.files,
      vehicle: request.vehicle,
    }

    this.permitService.createPermitRequest(permitRequest)
      .pipe(
        catchError((request) => {
          this.notify.error('Error Saving Permit Request');
          throw request;
        }))
      .subscribe((res) => {
        this.notify.success('Success', 'Permit Request Saved');
        this.router.navigate(['permit-requests']);
      })
  }

  public calculateAvailableSpaces(spaces, permits) {
    let result = spaces - permits;
    if (result >= 0) {
      return result;
    } else {
      return 0;
    }
  }

}
