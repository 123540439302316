import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Plan } from 'app/models/plan.model';
import { Zone } from 'app/models/zone.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class LocationService {

  constructor(
    private auth: AuthService,
    private httpClient: HttpClient
  ) {}

  public getCoordinates(zipCode: string) {
    return this.httpClient.get (
      "https://maps.googleapis.com/maps/api/geocode/json?sensor=false&address=" + zipCode + "&key=" + environment.googleMapsKey
    )
  }

  public getPlans(lon: string, lat: string): any {
    return this.httpClient.get(`${environment.API_HOST_V1}/permit/plan/near?lon=${lon}&lat=${lat}`);
  }

  public getPlanById(id: string): Observable<Plan> {
    return this.httpClient.get(`${environment.API_HOST_V1}/permit/plan/${id}`)
      .pipe(
        map((p: any) => {
          return new Plan().deserialize(p);
        })
      )
  }

  public getPermitsbyPlanId(id: string): Observable<Object> {
    return this.httpClient.get(`${environment.API_HOST_V1}/permit/search/${id}`);
  }

  public savePermit(permit) {
    return this.auth.post_v1(`/permit`, permit);
  }

  public getZoneById(zoneId: string): Observable<Zone> {
    return this.httpClient.get(`${environment.API_HOST_V1}/locations/zone/${zoneId}`)
    .pipe(
      map((z: any) => {
        return new Zone(z.zone);
      })
    )
  }
}