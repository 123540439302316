import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activate-permit-landing-page',
  templateUrl: './activate-permit-landing-page.component.html',
  styleUrls: ['./activate-permit-landing-page.component.scss']
})
export class ActivatePermitLandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
