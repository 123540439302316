import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, map, share, tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {ErrorService} from '../error/error.service';
import {Permit} from '../../models/permit.model';
import {User} from '../../models/user.model';
import {Client, Customer} from 'app/interfaces';
import * as  LogRocket from 'logrocket';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

interface PermitResponse {
  hasMore: boolean;
  permit: Permit[];
}

@Injectable()
export class CustomerService {

  private fetchingPermits: boolean = false;
  current: Observable<Customer>;
  permits: Observable<Permit[]>;
  constructor(
    private auth: AuthService,
    private error: ErrorService,
    private httpClient: HttpClient
  ) {
    this.current = this.fetchCustomer();
    this.permits = this.fetchPermits();
  }

  fetchPermits(): Observable<Permit[]> {
    return this.auth.get_v2(`/permit`)
      .pipe(
        catchError(this.error.handleError('Fetching Permits', [])),
        map((data?: PermitResponse) => {
          this.fetchingPermits = false;
          const permitArray = [];
          data.permit.forEach(d => permitArray.push(new Permit().deserialize(d)));
          return permitArray;
        }),
        share(),
      );
  }

  fetchCustomer(): Observable<Customer> {
    return this.auth.get_v2('/customer')
      .pipe(

        map((res: Customer) => {
          try {
            let email;
            let name;
            if (res.email) {
              email = res.email;
            }
            if (res.name) {
              name = [res.name.first || '', res.name.last || ''].join(' ');
            }
            LogRocket.identify(res._id, {
              name, email
            });
          } catch (_ignore) {
            // Don't error if LogRocket does or the customer format is wrong.
          }
          return res;
        }),
        catchError(this.error.handleError<Customer>('Fetch Customer')),
      )
  }

  fetchClient(id: string): Observable<Client | undefined> {
    return this.httpClient.get(`${environment.API_HOST_V1}/client/` + id)
      .pipe(
        catchError(this.error.handleError('Fetch Client', {
          client: []
        })),
        map((res: {client: Client[]}) => res.client[0])
      )
  }
}

interface LogMessage {
  action: string;
  when: Date;
  user?: User|string;
  details?: string;
  changes?: any;
}

export interface IProfile {
  name?: string;
  nickname?: string;
  picture?: string;
  id: string;
  email: string;
  username?: string;
  createdAt: string;
  updated_at: string | Date;
}
