import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Observable} from 'rxjs/internal/Observable';
import {Vehicle} from '../../models/vehicle.model';
import {catchError, map} from 'rxjs/operators';
import {ErrorService} from '../error/error.service';
import {DataTableService} from '../datatable.base';
import {InvoiceItem} from '../../models/invoiceItem.model';
import {HttpParams} from '@angular/common/http';

export interface GetInvoiceItemResults {
  invoiceItems: InvoiceItem[];
  hasMore: boolean;
}

@Injectable()
export class InvoiceService extends DataTableService {

  constructor(
    private auth: AuthService,
    private error: ErrorService
  ) {
    super();
  }

  public fetchInvoices(params, after: string): Observable<GetInvoiceItemResults> {
    const data: HttpParams = this.paramsToQuery(params, after);
    return this.auth.get_v2<GetInvoiceItemResults>('/fleet/invoices', data)
      .pipe(
        catchError(this.error.handleError('fetchInvoices', {invoiceItems: [], hasMore: false})),
        map((results: GetInvoiceItemResults) => {
          results.invoiceItems = results.invoiceItems.map((i) => new InvoiceItem().deserialize(i))
          return results;
        }),
      );
  }

}
