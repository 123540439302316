import { Amount } from "../amount";
// import { ChangeEvent } from "../helpers";

export enum TxnCategory {
    PaymentOnApproval = 'Payment On Approval',
    ManualPayment = 'Manual Payment',
    AutomaticRenewal = 'Automatic Renewal'
}

export enum TxnType {
    Charge = 'Charge',
    Refund = 'refund',
    Void = 'void',
}

export interface IPermitTransaction {
    _id: string;
    txnCategory: string;
    type: string; // "fiserv_ach" | "fiserv";
    state: string;
    subtotal: Amount;
    fees?: Amount;
    taxes?: Amount;
    amount: Amount; // total charged
    last4: string;
    network: string;
    txnType: TxnType;
    txnDate: Date;
    txnId: string;
    authCode: string;
    referenceNumber: string;
    maskedCardNumber?: string;
    metadata: {
        [key: string]: any;
    };
    customer: string;
    token?: string;
    merchid?: string;
    expiry?: number | string;
    requestId: string;
    planId: string;
    zoneId: string;
    skuId: string;
    customerId: string;
    clientId: string;
    account?: string;
    receiptFilename?: string;
    updatedAt?: Date;
    createdAt?: Date;
    // events?: ChangeEvent[];
}
export class PermitTransaction {
    _id: string;
    txnCategory: string;
    type: string;
    state: string;
    subtotal: Amount;
    fees?: Amount;
    taxes?: Amount;
    amount: Amount;
    last4: string;
    network: string;
    txnType: TxnType;
    txnDate: Date;
    txnId: string;
    authCode: string;
    referenceNumber: string;
    maskedCardNumber?: string;
    metadata: {
        [key: string]: any;
    };
    customer: string;
    token?: string;
    merchid?: string;
    expiry?: number | string;
    requestId: string;
    planId: string;
    skuId: string;
    zoneId?: string;
    customerId: string;
    clientId: string;
    account?: string;
    receiptFilename?: string;
    updatedAt?: Date;
    createdAt?: Date;
    // events?: ChangeEvent[];

    constructor(input: IPermitTransaction) {
        this._id = input._id;
        this.txnCategory = input.txnCategory;
        this.type = input.type;
        this.state = input.state; 
        this.subtotal = new Amount(input.subtotal); 
        this.fees = input.fees ? new Amount(input.fees) : undefined; 
        this.taxes = input.taxes ? new Amount(input.taxes) : undefined; 
        this.amount = new Amount(input.amount); 
        this.last4 = input.last4;
        this.network = input.network;
        this.txnType = input.txnType;
        this.txnDate = input.txnDate; 
        this.txnId = input.txnId;
        this.authCode = input.authCode;
        this.referenceNumber = input.referenceNumber;
        this.maskedCardNumber = input.maskedCardNumber;
        this.metadata = input.metadata;
        this.customer = input.customer;
        this.token = input.token;
        this.merchid = input.merchid;
        this.expiry = input.expiry;
        this.requestId = input.requestId;
        this.planId = input.planId;
        this.zoneId = input.zoneId;
        this.skuId = input.skuId;
        this.customerId = input.customerId;
        this.clientId = input.clientId;
        this.account = input.account;
        this.receiptFilename = input.receiptFilename;
        this.updatedAt = input.updatedAt;
        this.createdAt = input.createdAt;
        // this.events = input.events;
    }
}