<form [formGroup]="form" (ngSubmit)="onSaveSource()" class="ui form row">
  <div class="col-sm-6">
    <div class="card">
      <div class="card-header">
        <strong>Billing Address</strong>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name_first">Name <small>on Card</small></label>
              <input type="text" class="form-control" id="name_first" [formControl]="name_first" value="" placeholder="First">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="name_last">&nbsp;</label>
              <input type="text" class="form-control" id="name_last" [formControl]="name_last" value="" placeholder="Last">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group" [ngClass]="{'has-danger': !address_line1.valid && address_line1.touched}">
              <label for="address_line1">Address <small>Line 1</small></label>
              <input type="text" class="form-control" id="address_line1" [formControl]="address_line1" placeholder="" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="address_line2">Address <small>Line 2</small></label>
              <input type="text" class="form-control" id="address_line2" [formControl]="address_line2" placeholder="">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-9">
            <div class="form-group" [ngClass]="{'has-danger': !address_city.valid && address_city.touched}">
              <label for="address_city">City</label>
              <input type="text" class="form-control" id="address_city" [formControl]="address_city" placeholder="" required>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group" [ngClass]="{'has-danger': !address_state.valid && address_state.touched}">
              <label for="address_state">State</label>
              <input type="text" class="form-control" id="address_state" [formControl]="address_state" placeholder="" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group" [ngClass]="{'has-danger': !address_zip.valid && address_zip.touched}">
              <label for="address_zip">Zip</label>
              <input type="text" class="form-control" id="address_zip" [formControl]="address_zip" placeholder="" required>
            </div>
          </div>
          <div class="col-sm-9">
            <div class="form-group" [ngClass]="{'has-danger': !address_country.valid && address_country.touched}">
              <label for="address_country">Country</label>
              <input type="text" class="form-control" id="address_country" [formControl]="address_country" placeholder="" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group" [ngClass]="{'has-danger': !email.valid && email.touched}">
              <label for="email">Email</label>
              <input type="text" id="email" name="email" class="form-control" [formControl]="email" required>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-header">
        <strong>Credit Card</strong>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="ccnumber">Credit Card Number</label>
              <input type="text" class="form-control" data-private id="ccnumber" [formControl]="ccnumber" placeholder="0000 0000 0000 0000">
            </div>
          </div>
        </div>
        <!--/.row-->
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="ccexp">Expiration</label>
              <input type="text" class="form-control" id="ccexp" [formControl]="ccexp" placeholder="MM/YYYY">
            </div>
          </div>
          <div class="col-sm-4">
            <div class="form-group">
              <label for="cvv">CVV/CVC</label>
              <input type="text" class="form-control" data-private id="cvv" [formControl]="cvv" placeholder="123">
            </div>
          </div>
        </div>
        <!--/.row-->
      </div>
      <div class="card-footer">
        <button type="submit" class="btn btn-sm btn-primary mr-1" [disabled]="!form.valid"><i class="fa fa-dot-circle-o"></i> {{label || "Save Payment"}}</button>
        <button (click)="onCancel()" type="button" class="btn btn-sm btn-danger"><i class="fa fa-ban"></i> Cancel</button>
      </div>
    </div>
  </div>
</form>
