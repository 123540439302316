import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';

import {AppComponent} from './app.component';
import {NAV_DROPDOWN_DIRECTIVES} from './shared/nav-dropdown.directive';
import {SIDEBAR_TOGGLE_DIRECTIVES} from './shared/sidebar.directive';
import {AsideToggleDirective} from './shared/aside.directive';
import {BreadcrumbsComponent} from './shared/breadcrumb.component';
// Routing Module
import {AppRoutingModule} from './app.routing';
// Layouts
// Imported Modules
import {PagesModule} from './views/pages/pages.module';
import {HttpClientModule} from '@angular/common/http';
import {CustomerService} from './service/user/customer.service';
import {VehiclesService} from './service/vehicles/vehicles.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SourceService} from './service/source/source.service';
import {HeartlandPaymentModule} from './service/hps/hps.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PermitsService} from './service/permit/permits.service';
import {PaymentFormModule} from './views/permit-components/payments/payment-form/payment-form.module';
import {AuthComponent} from './views/auth/auth.component';
import {AuthService} from './service/auth/auth.service';
import {ToastrModule} from 'ngx-toastr';
import {PaymentsModule} from './views/permit-components/payments/payments.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {DefaultContainerComponent} from './containers/default-container/default-container.component';
import {AchPaymentFormComponent} from './views/fleet-parking-components/ach-payment-form/ach-payment-form.component';
import {InvoiceService} from './service/invoice/invoice.service';
import {AppBreadcrumbService} from './shared/breadcrumb.service';
import {NgxSelectModule} from 'ngx-select-ex';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { SearchComponent } from './views/search/search.component';
import { LocationService } from './service/location/location.service';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'environments/environment';
import { LocationComponent } from './views/location/location.component';
import { RequestFormComponent } from './views/location/request-form/request-form.component';
import { PermitProductPageComponent } from './views/location/product-page/product-page.component';
import { PermitRequestFormModule } from '@meterfeeder/permit-request-form';
import { ProductPageModule } from '@meterfeeder/permit-product-page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestPermitsModule } from '@meterfeeder/request-permits';
import { PermitVehiclesModule } from '@meterfeeder/permit-vehicles';
import { ManagePaymentsModule } from '@meterfeeder/manage-payments';
import { PermitsViewModule } from '@meterfeeder/permits-view';
import { CardConnectService } from './service/cardconnect/cardconnect.service';
import { CustomerProfileModule } from '@meterfeeder/customer-profile';
import { CustomerProfileComponent } from './views/permit-components/customer-profile/customer-profile.component';
import { AuthModule } from '@auth0/auth0-angular';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    CustomerProfileModule,
    PagesModule,
    PaymentFormModule,
    PaymentsModule,
    ManagePaymentsModule,
    PermitRequestFormModule,
    PermitVehiclesModule,
    PermitsViewModule,
    ProductPageModule,
    RequestPermitsModule,
    HttpClientModule,
    HeartlandPaymentModule,
    NgbTooltipModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      tapToDismiss: true,
    }),
    NgxSelectModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }),
    AuthModule.forRoot({
      ...environment.auth0,
      clientId: environment.auth0.clientID
    }),
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [
    AppComponent,
    AchPaymentFormComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    AuthComponent,
    DefaultContainerComponent,
    DashboardComponent,
    SearchComponent,
    LocationComponent,
    RequestFormComponent,
    PermitProductPageComponent,
    CustomerProfileComponent,
  ],
  providers: [
    AppBreadcrumbService,
    AuthService,
    CustomerService,
    InvoiceService,
    VehiclesService,
    SourceService,
    PermitsService,
    LocationService,
    CardConnectService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
