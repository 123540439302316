import {ObjectId, ILedger, Payment, TransactionState, TxnType, Permit, Reservation, Ticket} from 'app/interfaces';
import {Amount} from 'app/amount';
import {Deserializable} from './deserializable';


export class Ledger implements Deserializable, ILedger {
  _id: ObjectId;
  amount: Amount;
  authAmount?: number;
  batchId?: string;
  batchSeqNbr?: string;
  cardholder?: {
    company?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    last4?: string;
    type: string;
    zip?: string;
  };
  metadata: any;
  payment?: Payment;
  readonly service: undefined | Permit | Reservation | Ticket;
  serviceId: string;
  serviceType: string;
  settlementAmount?: string;
  state: TransactionState;
  total?: Amount;
  txnDate: Date;
  txnId: number | string;
  txnType: TxnType;

  public deserialize(input: any): Ledger {
    const ledger = new Ledger();
    Object.assign(ledger, input);
    ledger.txnDate = new Date(input.txnDate);
    if (input.amount && input.amount.value) {
      ledger.amount = new Amount(input.amount);
    } else {
      const sa = parseFloat(input.settlementAmount);
      ledger.amount = new Amount(sa * 100);
    }
    return ledger;
  }
}
