import { CdnImage, Client, ObjectId, SavedRule, ZoneTypes } from 'app/interfaces';

export class Zone {
  _id: ObjectId;
  active: boolean;
  currency: string;
  description?: string;
  displayName: string;
  enforcementActive: boolean;
  enforcementTimes: SavedRule[];
  images?: CdnImage[];
  integrations: any;
  label: string;
  live: Date;
  maxMinutes?: number;
  municipality: Client | ObjectId;
  payBy: ZoneTypes;
  paymentsAccepted: boolean;
  published: boolean;
  prefix?: string;
  start: Date;
  state: string;
  country: string;
  iso: string;
  tags: string[];
  timezone: string;
  center: {
    coordinates: number[]
  }

  constructor(input: any) {
    this._id = input._id;
    this.active = input.active;
    this.currency = input.currency;
    this.description = input.description;
    this.displayName = input.displayName;
    this.enforcementActive = input.enforcementActive;
    this.enforcementTimes = input.enforcementTimes;
    this.images = input.images;
    this.integrations = input.integrations;
    this.label = input.label;
    this.live = input.live;
    this.maxMinutes = input.maxMinutes;
    this.municipality = input.municipality;
    this.payBy = input.payBy;
    this.paymentsAccepted = input.paymentsAccepted;
    this.published = input.published;
    this.prefix = input.prefix;
    this.start = input.start;
    this.state = input.state;
    this.country = input.country;
    this.iso = input.iso;
    this.tags = input.tags;
    this.timezone = input.timezone;
    this.center = input.center;
  }
}
