import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../service/auth/auth.service';
import {Router} from '@angular/router';
import {Auth0UserProfile} from 'auth0-js';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public error: any = null;

  constructor(public auth: AuthService, public router: Router) {
    this.auth.handleLoginCallback()
      .then(
        (profile: Auth0UserProfile) => {
          this.auth.setLogRocketInfo(profile);
          const returnUrl = localStorage.getItem('returnUrl');
          if (returnUrl) {
            console.log({returnUrl}, 'Loading Saved URL');
            localStorage.removeItem('returnUrl');
            this.router.navigateByUrl(returnUrl);
          } else {
            console.log('No saved URL');
            this.router.navigate(['/']);
          }
        },
        (err: any) => {
          this.error = {
            title: 'Unexpected Error',
            message: err.errorDescription
          };
          switch (err.error) {
            case 'access_denied':
              this.error.title = 'Access Denied';
              break;
          }
        }
      );
  }

  ngOnInit() {

  }

}
