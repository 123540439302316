import {HpsDuration, HpsScheduleFrequency, HpsStatus} from 'app/interfaces';
import {Amount} from 'app/amount';

export class Schedule {
  scheduleKey!: number;
  scheduleIdentifier!: string;
  customerKey?: number;
  scheduleName!: string;
  scheduleStatus!: HpsStatus;
  paymentMethodKey!: string;
  subtotalAmount!: Amount;
  taxAmount!: Amount;
  totalAmount!: Amount;
  deviceID!: number;
  startDate!: Date;
  processingDateInfo?: number | 'First' | 'Last';
  frequency!: HpsScheduleFrequency;
  endDate?: Date ;
  reprocessingCount!: number;
  emailReceipt!: string;
  emailAdvanceNotice!: string;
  debtRepayInd = false;
  invoiceNbr?: string;
  poNumber?: string;
  description?: string;
  statusSetDate!: Date;
  nextProcessingDate!: Date;
  previousProcessingDate?: Date;
  approvedTransactionCount!: number;
  failureCount!: number;
  totalApprovedAmountToDate!: number;
  numberOfPaymentsRemaining!: number;
  cancellationDate?: Date;
  creationDate!: Date;
  lastChangeDate!: Date;
  scheduleStarted!: boolean;
  duration!: HpsDuration;
  deviceName!: string;
  paymentMethod!: string;
  paymentMethodType!: string;
  cardaccountNumberLast4!: string;

  constructor(input: Schedule) {
    Object.assign<Schedule, Schedule>(this, input);
    this.subtotalAmount = new Amount(input.subtotalAmount);
    this.taxAmount = new Amount(input.taxAmount);
    this.totalAmount = new Amount(input.totalAmount);
    this.nextProcessingDate = new Date(input.nextProcessingDate);
    this.startDate = new Date(input.startDate);
    this.endDate = input.endDate ? new Date(input.endDate) : undefined;
    this.cancellationDate = input.cancellationDate ? new Date(input.cancellationDate) : undefined;
    this.previousProcessingDate = input.previousProcessingDate ? new Date(input.previousProcessingDate) : undefined;
    this.statusSetDate = new Date(input.statusSetDate);
    this.creationDate = new Date(input.creationDate);
    this.lastChangeDate = new Date(input.lastChangeDate);
  }
}
