// Layouts

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './views/auth/auth.guard';
import {AuthComponent} from './views/auth/auth.component';
import {DefaultContainerComponent} from './containers/default-container/default-container.component';
import {AchPaymentFormComponent} from './views/fleet-parking-components/ach-payment-form/ach-payment-form.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import { SearchComponent } from './views/search/search.component';
import { RequestFormComponent } from './views/location/request-form/request-form.component';
import { PermitProductPageComponent } from './views/location/product-page/product-page.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    // redirectTo: 'auth',
    // pathMatch: 'full',
  },
  {
    path: 'callback',
    component: AuthComponent,
    // pathMatch: 'full'
  },
  {
    path: 'search',
    component: SearchComponent,
  },
  // {
  //   path: 'location/:clientslug/:planslug/:id',
  //   component: RequestFormComponent
  // },
  {
    path: 'location/:id',
    component: PermitProductPageComponent
  },
  {
    path: 'location/:planslug/:id',
    component: RequestFormComponent
  },
  // {
  //   path: 'location/:id',
  //   component: LocationComponent,
  // },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DefaultContainerComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      {
        path: 'ach',
        component: AchPaymentFormComponent,
        data: {
          title: 'ACH'
        }
      },
      {
        path: 'commuter-vehicle',
        loadChildren: () => import('./views/permit-components/vehicles/vehicles.module').then(m => m.VehiclesModule),
      },
      {
        path: 'payments-on-file',
        loadChildren: () => import('./views/permit-components/payments/payments.module').then(m => m.PaymentsModule),
      },
      {
        path: 'commuter-permits',
        loadChildren: () => import('./views/permit-components/permits/permits.module').then(m => m.PermitsModule),
      },
      {
        // reroute
        path: 'fleet-vehicle',
        // loadChildren: () => import('./views/fleet-parking-components/vehicles-fleet-parking/vehicles-fleet-parking.module')
        //   .then(m => m.VehiclesFleetParkingModule),
        redirectTo: 'commuter-permits',
        pathMatch: 'full'
      },
      {
        // reroute
        path: 'invoices',
        // loadChildren: () => import('./views/fleet-parking-components/invoices/invoices.module').then(m => m.InvoiceModule),
        redirectTo: 'commuter-permits',
        pathMatch: 'full'
      },
      {
        // reroute
        path: 'fleet-integration',
        // loadChildren: () => import('./views/fleet-parking-components/extensions/extensions.module').then(m => m.ExtensionsModule),
        redirectTo: 'commuter-permits',
        pathMatch: 'full'
      },
      {
        // reroute
        path: 'new-permit',
        // loadChildren: () => import('./views/permit-components/permit-creation-wizard/permit-creation-wizard.module')
        //   .then(m => m.PermitCreationWizardModule)
        redirectTo: 'commuter-permits',
        pathMatch: 'full'
      },
      {
        path: 'permit-requests',
        loadChildren: () => import('./views/permit-components/permit-requests/permit-requests.module')
          .then(m => m.PermitRequestsModule)
      },
      {
        path: 'customer-profile',
        loadChildren: () => import('./views/permit-components/customer-profile/customer-profile-routing.module')
          .then(m => m.CustomerProfileRoutingModule)
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { enableTracing: false, useHash: false, relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
