<div [hidden]="viewingMoreInformation" class="animated fadeIn">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <span>
            <p><i class="fa fa-money"></i> Payment Methods</p>
          </span>
        </div>
        <div class="card-body mr-2 ml-2">

          <div *ngIf="isWithinPaymentProcessingWindow(); else payments">
            <div class="alert alert-warning" role="alert">
              <h4 class="alert-heading">Payments are processing...</h4>
              <p>We've reached the end of the monthly payment period! Payments are processing and your permits are being updated. The processing period occurs on the 1st of the month and takes up to 12 hours.</p>
              <hr>
              <p class="mb-0">You will be able to view and manage your payments once updating completes. Thank you for your patience.</p>
            </div>
          </div>
          <ng-template #payments>
            <div *ngIf="paymentProcessing" class="alert alert-success alert-dismissible fade show" role="alert">
              <strong>Payment processing...</strong> this should only take a moment.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="row" *ngIf="noRequests">
              <div class="col">
                <p>You currently have no active payment plans.</p>
              </div>
            </div>

            <div class="row" *ngIf="!formattedRequests.length && !loading && !noRequests">
              <div class="col">
                <lib-manage-payments></lib-manage-payments>
              </div>
            </div>

            <div class="row" *ngIf="formattedRequests.length && !loading">
              <div class="col">
                <lib-manage-payments [requests]="formattedRequests" [cardConnectUrl]="ccurl"
                  (onEditPaymentMethod)="handleEditPaymentMethod($event)" (onMakePayment)="handleMakePayment($event)"
                  (onToggleAutoRenewal)="handleToggleAutoRenewal($event)"></lib-manage-payments>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>