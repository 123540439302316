import { NgModule } from '@angular/core';
import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentsComponent } from './payments.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { PaymentFormModule } from './payment-form/payment-form.module';
import {PaymentViewComponent} from './payment-view/payment-view.component';
import {DataTablesModule} from 'angular-datatables';
import {PaymentNewComponent} from './payment-new/payment-new.component';
import { BrandIconPipe } from './brand-icon.pipe';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ChartsModule} from 'ng2-charts';
import { ManagePaymentsModule } from '@meterfeeder/manage-payments';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    CommonModule,
    DataTablesModule,
    ModalModule.forRoot(),
    NgbTooltipModule,
    PaymentsRoutingModule,
    PaymentFormModule,
    ManagePaymentsModule
  ],
  declarations: [
    PaymentsComponent,
    PaymentViewComponent,
    PaymentNewComponent,
    BrandIconPipe,
  ],
  exports: [
    BrandIconPipe,
  ]
})
export class PaymentsModule { }
