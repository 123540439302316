import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import * as auth0 from 'auth0-js';
import { from, fromEvent, Observable, throwError } from 'rxjs';
import { Auth0DecodedHash, Auth0UserProfile } from 'auth0-js';
import { AuthService as Auth0Service, IdToken } from "@auth0/auth0-angular";
import * as LogRocket from 'logrocket';
import { Router } from '@angular/router';
import { map, skipWhile, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  auth0 = new auth0.WebAuth(environment.auth0);

  get accessToken(): string {
    return localStorage.getItem('access_token');
  }

  get profile(): Auth0UserProfile {
    return JSON.parse(localStorage.getItem('profile'));
  }

  public addRole(role: string) {
    const profile: Auth0UserProfile = JSON.parse(localStorage.getItem('profile'));
    profile.app_metadata.authorization.roles.push(role);
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  public addPermission(permission: string) {
    const profile: Auth0UserProfile = JSON.parse(localStorage.getItem('profile'));
    profile.app_metadata.authorization.permissions.push(permission);
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth0Service: Auth0Service
  ) { }


  /**
   * Helper function to make an authenticated HTTP GET request to the API v1 server.
   *
   * @param url
   * @param params
   */
  public get_v1<T>(url: string, params?: HttpParams): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.get<T>(environment.API_HOST_V1 + url, {
      headers,
      params
    });
  }

  /**
   * Helper function to make an authenticated HTTP DELETE request to the API v1 server.
   *
   * @param url
   */
  public delete_v1<T>(url: string): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.delete<T>(environment.API_HOST_V1 + url, { headers: headers });
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API v1 server.
   *
   * @param url
   * @param data
   */
  public patch_v1<T, R>(url: string, data: R): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.patch<T>(environment.API_HOST_V1 + url, data, {
      headers: headers
    });
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API v1 server.
   *
   * @param url
   * @param data
   */
  public post_v1<T, R>(url: string, data: R): Observable<T> {
    if (this.accessToken) {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer ' + this.accessToken);
      // TODO: Update API  .append('ma-version', environment.version);
      return this.http.post<T>(environment.API_HOST_V1 + url, data, {
        headers: headers
      });
    } else {
      return this.http.post<T>(environment.API_HOST_V1 + url, data);
    }
  }

  /**
   * Helper function to make an authenticted HTTP PUT request to the API v1 server.
   *
   * @param url
   * @param data
   */
  public put_v1<R>(url: string, data: R): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.put(environment.API_HOST_V1 + url, data, {
      headers: headers
    });
  }

  /**
   * Helper function to make an authenticated HTTP GET request to the API v2 server.
   *
   * @param url
   * @param params
   */
  public get_v2<T>(url: string, params?: HttpParams): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.get<T>(environment.API_HOST_V2 + url, {
      headers,
      params
    });
  }

  /**
   * Helper function to make an authenticated HTTP DELETE request to the API v2 server.
   *
   * @param url
   */
  public delete_v2<T>(url: string): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.delete<T>(environment.API_HOST_V2 + url, { headers: headers });
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API server.
   *
   * @param url
   * @param data
   */
  public patch_v2<T, R>(url: string, data: R): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.patch<T>(environment.API_HOST_V2 + url, data, {
      headers: headers
    });
  }

  /**
   * Helper function to make an authenticated HTTP POST request to the API v2 server.
   *
   * @param url
   * @param data
   */
  public post_v2<T, R>(url: string, data: R): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.post<T>(environment.API_HOST_V2 + url, data, {
      headers: headers
    });
  }

  /**
   * Helper function to make an authenticted HTTP PUT request to the API v2 server.
   *
   * @param url
   * @param data
   */
  public put_v2<R>(url: string, data: R): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', 'Bearer ' + this.accessToken)
      ; // TODO: Update API  .append('ma-version', environment.version);
    return this.http.put(environment.API_HOST_V2 + url, data, {
      headers: headers
    });
  }


  /**
   * Use access token to retrieve user's profile from auth0 and set session
   */
  public getUserProfile(authResult: Auth0DecodedHash): Promise<Auth0UserProfile> {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(authResult.accessToken, (err: any, profile: Auth0UserProfile) => {
        if (err) {
          return reject(err);
        }
        this.setSession(authResult, profile);
        return resolve(profile);
      });
    });
  }

  /**
   * Parses the auth_token from the url hash and sets values into local storage.
   */
  public handleLoginCallback(): Promise<Auth0UserProfile> {
    return new Promise((resolve, reject) => {
      // When Auth0 hash parsed, get_v1 profile
      this.auth0.parseHash((err: any, authResults: Auth0DecodedHash) => {
        if (err) {
          if (err.error === 'invalid_token') {
            setTimeout(() => {
              this.router.navigateByUrl('/');
            }, 3000); // Show the message for 3 sec and then navigate to clear the error.
          }
          return reject(err);
        }
        return resolve(this.getUserProfile(authResults));
      });
    });
  }

  /**
   * Check status of the token in local storage.
   */
  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const token = localStorage.getItem('access_token');
    return token && new Date().getTime() < expiresAt;
  }

  /**
   * Sets the User profile to LogRocket so tracking works
   */
  public setLogRocketInfo(profile: Auth0UserProfile) {
    if (environment.production) {
      LogRocket.identify(profile.user_id, {
        name: profile.name || [profile.given_name, profile.family_name].join(' '),
        email: profile.email,
        picture: profile.picture,
        client: profile.clientID,
        username: profile.username
      });
    }
  }

  /**
   * Checks the session and looks up the user profile with auth0.
   */
  public checkSession(): Promise<Auth0UserProfile> {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult: any) => {
        if (err) {
          return reject(err);
        }
        if (authResult && authResult.accessToken) {
          return this.getUserProfile(authResult).then(resolve).catch(reject)
        }
        return reject(new Error('No AccessToken after login'));
      });
    });
  }

  /**
   * Update the local storage information for the current login session.
   *
   * @param authResult
   * @param profile
   */
  private setSession(authResult: Auth0DecodedHash, profile: Auth0UserProfile | IdToken): void {
    // Set the time that the Access Token will expire at
    const re = new RegExp('^' + environment.auth0.namespace + '(.+)$');
    if (profile) {
      Object.keys(profile).forEach((key: string) => {
        const matches = key.match(re);
        if (matches) {
          profile[matches[1]] = profile[key];
        }
      });
    }
    profile.user_id = profile.user_id || profile.sub;

    // if the profile has an exp property then use that for the token, otherwise use the 
    // `expiresIn` property from the authResult.
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    if (Object.hasOwnProperty.call(profile, 'exp')) {
      expiresAt = JSON.stringify(profile['exp'] * 1000);
    }

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('profile', JSON.stringify(profile));
  }

  public loginPopup() {
    this.auth0Service.loginWithPopup({ container: '' });
    return this.auth0Service.idTokenClaims$
      .pipe(
        skipWhile((p): p is IdToken => !p),
        take(1),
        map((profile: IdToken) => {
          console.log(profile, 'Loged in Profile');
          try {
            this.setSession({
              accessToken: profile.__raw,
              idToken: profile.__raw,
              expiresIn: profile.exp ? profile.exp / 100 : undefined,
            }, profile);
            return profile;
          } catch (e) {
            console.error(e);
            throwError(e);
          }
        })
      )
  }

  public login(): void {
    this.auth0.authorize();
  }

  public applyToken(token): Observable<any> {
    return this.post_v1(`/auth/token/${token}`, {});
  }

  public getProfile(): Observable<Auth0UserProfile> {
    return new Observable((obs) => {
      const token = this.getAccessToken();
      this.auth0.client.userInfo(token, (err, profile) => {
        if (err) {
          obs.error(err);
        } else {
          obs.next(profile);
        }
      });
    });
  }

  public can(...parts: string[]): boolean {
    try {
      const permission = parts.join(':');
      const allowed = this.profile.app_metadata.authorization.permissions;
      return allowed.indexOf(permission) !== -1;
    } catch (e) {
      return false;
    }
  }

  public getAccessToken(): string {
    return localStorage.getItem('access_token');
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.auth0.logout({
      clientID: environment.auth0.clientID,
      returnTo: `${window.location.origin}`
    });
  }

  public resendVerificationEmail(userId: string): Observable<Object> {
    return this.http.get(environment.API_HOST_V1 + '/user/verification/' + userId);
  }
}
