import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivatePermitLandingPageComponent } from './activate-permit-landing-page/activate-permit-landing-page.component';
import {ErrorComponent} from './error/error.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Example Pages'
    },
    children: [
      {
        path: 'error',
        component: ErrorComponent,

      },
      {
        // commuter-permits/activate/62d865ccdb0de26730d3eee2
        path: 'commuter-permits/activate/:id',
        component: ActivatePermitLandingPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
